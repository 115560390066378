import { useEffect, Fragment, useState } from 'react';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import _ from 'lodash';

import { bookingUtils } from 'lib/booking';
import { utils } from 'lib/utils';

// Components
import PriceCardContainer from 'components/createBooking/components/priceCardContainer';
import CardContainer from 'components/createBooking/components/cardContainer';
import { Button } from '@askable/ui/components/ui/button';
import { Label } from '@askable/ui/components/ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@askable/ui/components/ui/select';

function StudyDuration(props: any) {
  const booking = _.get(props, 'booking');
  const [bookingState, setBookingState] = useState(booking);
  const [bookingData, setBookingData] = useState(null);
  const arrayOfDaysWeeks = utils.generateNumbersArray(1, 31);
  const [, setFrequencyError] = useState(false);
  const [, setPeriodError] = useState(false);
  const [, setWorkloadError] = useState(false);

  useEffect(() => {
    props.updateLastStep({
      step: 'Project Setup',
      subStep: `/booking-setup/${props.booking._id}/project-setup/study-duration`,
      stepId: 'project_setup_study_duration',
    });
  }, []);

  useEffect(() => {
    setBookingState(props.booking);
  }, [props.booking]);

  useEffect(() => {
    props.renderRightContent(rightContent());
    props.renderRightAppPreview(null);

    const periodFrequency = _.get(booking, 'config.longitudinal_study.period.frequency', 1);
    const workloadFrequency = _.get(booking, 'config.longitudinal_study.participant_workload.frequency', 1);
    if (periodFrequency < workloadFrequency) setFrequencyError(true);
    setPeriodError(!_.get(booking, 'config.longitudinal_study.period.time'));
    setWorkloadError(!_.get(booking, 'config.longitudinal_study.participant_workload.time'));
  }, [booking, bookingState]);

  const rightContent = () => {
    const cardTexts1 = [
      {
        cardText:
          'Yes they do. Since the price above includes participant incentives, running longer sessions will require higher incentives and therefore cost a bit more.',
      },
    ];
    let typeLabel = 'sessions';
    if (bookingUtils.isLongitudinal(booking)) typeLabel = 'studies';
    else if (bookingUtils.isSurvey(booking)) typeLabel = 'surveys';
    else if (bookingUtils.isOnlineTask(booking)) typeLabel = 'tasks';

    return (
      <Fragment>
        <p className="cardContainerTitle">Pricing</p>
        <PriceCardContainer
          booking={bookingState}
          bookingSteps={props.bookingSteps}
          team={_.get(props, 'team')}
          context={props.context}
        />
        <p className="cardContainerTitle additionalTitle">Faqs</p>
        <CardContainer
          cardId="__taskDurationCard1"
          cardClass="slideInAnimationDelay80"
          cardTitle={`Do longer ${typeLabel} cost more?`}
          cardTexts={cardTexts1}
          onLinkClick={() => {
            window.open(
              'http://help.askable.com/en/articles/4254373-learning-more-about-credits#h_8ee2bdaff0',
              '_blank',
            );
          }}
        />
      </Fragment>
    );
  };

  const onClickNext = () => {
    const redirectTo = `/booking-setup/${booking._id}/project-setup/participant-selection`;
    props.history.push({ pathname: redirectTo, booking: bookingData, bookingState });
  };

  const updateLongitudinalSession = (bookingObj: any) => {
    // Get total minutes workload
    const totalMinutesWorkload = bookingUtils.getLongitudinalStudyParticipantWorkload(bookingObj);
    // Get total extra minutes
    const totalMinutesExtraTime = bookingUtils.getLongitudinalStudyExtraTime(bookingObj);
    // Get total days in period
    const totalDaysPeriod = bookingUtils.getLongitudinalStudyPeriod(bookingObj);

    return {
      session: {
        duration: Math.round(totalMinutesWorkload + totalMinutesExtraTime),
        time_limit: totalDaysPeriod * 60 * 60, // check this calc
      },
    };
  };

  const onChangeParticipantWorkloadTime = (value: any) => {
    setWorkloadError(false);
    const bookingStateObj = {
      ...bookingState,
      config: {
        ...bookingState.config,
        longitudinal_study: {
          ...bookingState.config.longitudinal_study,
          participant_workload: {
            ...bookingState.config.longitudinal_study.participant_workload,
            time: value.time ? parseInt(value.time, 10) : null,
            measure: value.measure || null,
          },
        },
      },
    };
    setBookingState(bookingStateObj);

    const sessionTime = updateLongitudinalSession(bookingStateObj);
    const bookingObj = {
      config: {
        longitudinal_study: {
          ...bookingState.config.longitudinal_study,
          participant_workload: {
            ...bookingState.config.longitudinal_study.participant_workload,
            time: value.time ? parseInt(value.time, 10) : null,
            measure: value.measure || null,
          },
        },
        ...sessionTime,
      },
    };

    if (
      _.get(bookingObj, 'config.longitudinal_study.participant_workload.time') !==
        _.get(booking, 'config.longitudinal_study.participant_workload.time') ||
      _.get(bookingObj, 'config.longitudinal_study.participant_workload.measure') !==
        _.get(booking, 'config.longitudinal_study.participant_workload.measure') ||
      _.get(bookingObj, 'config.longitudinal_study.participant_workload.frequency') !==
        _.get(booking, 'config.longitudinal_study.participant_workload.frequency') ||
      _.get(bookingObj, 'config.longitudinal_study.period.time') !==
        _.get(booking, 'config.longitudinal_study.period.time') ||
      _.get(bookingObj, 'config.longitudinal_study.period.frequency') !==
        _.get(booking, 'config.longitudinal_study.period.frequency')
    ) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ config: { session: { duration:... Remove this comment to see the full error message
      setBookingData(bookingObj);
      props.history.replace({ booking: bookingObj, bookingState: bookingStateObj });
    } else {
      setBookingData(null);
      props.history.replace({ booking: null, bookingState: null });
    }
  };

  const onChangeParticipantWorkloadFrequency = (value: any) => {
    setFrequencyError(false);
    const bookingStateObj = {
      ...bookingState,
      config: {
        ...bookingState.config,
        longitudinal_study: {
          ...bookingState.config.longitudinal_study,
          participant_workload: {
            ...bookingState.config.longitudinal_study.participant_workload,
            frequency: Number(value) || null,
          },
        },
      },
    };
    setBookingState(bookingStateObj);

    const sessionTime = updateLongitudinalSession(bookingStateObj);
    const bookingObj = {
      config: {
        longitudinal_study: {
          ...bookingState.config.longitudinal_study,
          participant_workload: {
            ...bookingState.config.longitudinal_study.participant_workload,
            frequency: Number(value) || null,
          },
        },
        ...sessionTime,
      },
    };
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ config: { session: { duration:... Remove this comment to see the full error message
    setBookingData(bookingObj);

    if (
      _.get(bookingObj, 'config.longitudinal_study.participant_workload.time') !==
        _.get(booking, 'config.longitudinal_study.participant_workload.time') ||
      _.get(bookingObj, 'config.longitudinal_study.participant_workload.measure') !==
        _.get(booking, 'config.longitudinal_study.participant_workload.measure') ||
      _.get(bookingObj, 'config.longitudinal_study.participant_workload.frequency') !==
        _.get(booking, 'config.longitudinal_study.participant_workload.frequency') ||
      _.get(bookingObj, 'config.longitudinal_study.period.time') !==
        _.get(booking, 'config.longitudinal_study.period.time') ||
      _.get(bookingObj, 'config.longitudinal_study.period.frequency') !==
        _.get(booking, 'config.longitudinal_study.period.frequency')
    ) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ config: { session: { duration:... Remove this comment to see the full error message
      setBookingData(bookingObj);
      props.history.replace({ booking: bookingObj, bookingState: bookingStateObj });
    } else {
      setBookingData(null);
      props.history.replace({ booking: null, bookingState: null });
    }
  };

  const onChangeBookingPeriod = async (value: any, field: any) => {
    const fieldValue = value ? parseInt(value, 10) : null;
    if (field === 'frequency' && fieldValue) {
      setFrequencyError(false);
    }
    if (field === 'time' && fieldValue) {
      setPeriodError(false);
    }

    const bookingStateObj = {
      ...bookingState,
      config: {
        ...bookingState.config,
        longitudinal_study: {
          ...bookingState.config.longitudinal_study,
          period: {
            ...bookingState.config.longitudinal_study.period,
            [field]: fieldValue || null,
          },
        },
      },
    };
    setBookingState(bookingStateObj);

    const sessionTime = updateLongitudinalSession(bookingStateObj);
    const bookingObj = {
      config: {
        longitudinal_study: {
          ...bookingState.config.longitudinal_study,
          period: {
            ...bookingState.config.longitudinal_study.period,
            [field]: fieldValue || null,
          },
        },
        ...sessionTime,
      },
    };
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ config: { session: { duration:... Remove this comment to see the full error message
    setBookingData(bookingObj);

    if (
      _.get(bookingObj, 'config.longitudinal_study.participant_workload.time') !==
        _.get(booking, 'config.longitudinal_study.participant_workload.time') ||
      _.get(bookingObj, 'config.longitudinal_study.participant_workload.measure') !==
        _.get(booking, 'config.longitudinal_study.participant_workload.measure') ||
      _.get(bookingObj, 'config.longitudinal_study.participant_workload.frequency') !==
        _.get(booking, 'config.longitudinal_study.participant_workload.frequency') ||
      _.get(bookingObj, 'config.longitudinal_study.period.time') !==
        _.get(booking, 'config.longitudinal_study.period.time') ||
      _.get(bookingObj, 'config.longitudinal_study.period.frequency') !==
        _.get(booking, 'config.longitudinal_study.period.frequency')
    ) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ config: { session: { duration:... Remove this comment to see the full error message
      setBookingData(bookingObj);
      props.history.replace({ booking: bookingObj, bookingState: bookingStateObj });
    } else {
      setBookingData(null);
      props.history.replace({ booking: null, bookingState: null });
    }
  };

  const renderParticipantWorkloadContainer = () => {
    const workloadTime = _.get(bookingState, 'config.longitudinal_study.participant_workload.time', 15);
    const workloadMeasure = _.get(bookingState, 'config.longitudinal_study.participant_workload.measure', 'minutes');
    const workloadFrequency = _.get(bookingState, 'config.longitudinal_study.participant_workload.frequency', 1);
    const workloadLabel = `${workloadTime} ${bookingUtils.getMeasureLabel(workloadMeasure, workloadTime > 1)}`;

    return (
      <div className="flex flex-col gap-2">
        <Label htmlFor="selectParticipantWorkload">Participants workload</Label>
        <div className="flex items-center gap-2">
          <Select
            onValueChange={(value) => {
              return onChangeParticipantWorkloadTime(bookingUtils.measureTimes().find((m) => m.label === value));
            }}
            defaultValue={workloadLabel}
          >
            <SelectTrigger id="selectParticipantWorkload">
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              {bookingUtils.measureTimes().map((item: any) => (
                <SelectItem key={item.label} value={item.label}>
                  {item.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>

          <span className="text-sm">per</span>

          <Select onValueChange={onChangeParticipantWorkloadFrequency} defaultValue={String(workloadFrequency)}>
            <SelectTrigger>
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              {bookingUtils.frequencyTypes().map((item: any) => (
                <SelectItem key={`workload_freq_${item.value}`} value={String(item.value)}>
                  {item.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        {!workloadTime || workloadTime === 0 ? (
          <div className="text-xs text-destructive">This field is required</div>
        ) : null}
      </div>
    );
  };

  const renderBookingPeriodContainer = () => {
    const periodTime = _.get(bookingState, 'config.longitudinal_study.period.time');
    const periodFrequency = _.get(bookingState, 'config.longitudinal_study.period.frequency', 2);
    // You cannot select a period frequency lower than the workload frequency
    const workloadFrequency = _.get(bookingState, 'config.longitudinal_study.participant_workload.frequency', 1);

    return (
      <div className="flex flex-col gap-2">
        <Label htmlFor="selectStudyPeriod">To be done over</Label>
        <div className="flex items-center gap-2">
          <Select
            onValueChange={(value) => onChangeBookingPeriod(Number(value), 'time')}
            defaultValue={String(periodTime)}
          >
            <SelectTrigger id="selectStudyPeriod">
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              {arrayOfDaysWeeks.map((item: any) => (
                <SelectItem key={`period_${item}`} value={String(item)}>
                  {item}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>

          <Select
            onValueChange={(value) => onChangeBookingPeriod(value, 'frequency')}
            defaultValue={String(periodFrequency)}
          >
            <SelectTrigger>
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              {bookingUtils.frequencyTypes(true).map((item: any) => (
                <SelectItem key={`freq_${item.label}`} value={String(item.value)}>
                  {item.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        {!periodTime || periodTime === 0 ? (
          <div className="text-xs text-destructive">This field is required</div>
        ) : null}

        {periodFrequency < workloadFrequency ? (
          <div className="text-xs text-destructive">Period must be greater than workload</div>
        ) : null}
      </div>
    );
  };

  return (
    <div className="createBookingContent">
      <h1 id="__pageTitle" className="title">
        Longitudinal study workload and period
      </h1>

      <div className="flex flex-col gap-4">
        {renderParticipantWorkloadContainer()}

        <div className="text-medium">Study period</div>
        {renderBookingPeriodContainer()}
      </div>

      <div className="buttonNextContainer">
        <Button variant="primary" size="lg" onClick={onClickNext}>
          Next
        </Button>
      </div>
    </div>
  );
}

export default deprecatedWithRouter(StudyDuration);
