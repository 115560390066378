import { useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { FormControl, FormField, FormItem, FormLabel } from '@askable/ui/components/ui/form';
import { Textarea } from '@askable/ui/components/ui/textarea';
import type { FormValues } from 'containers/Studies/BuildStudy/containers/BlockEditor';

interface TextareaFieldProps {
  debounce?: number;
  name: keyof Pick<FormValues, 'instructions'>;
  label: string;
  placeholder?: string;
}

export const TextareaField = ({ debounce = 800, name, label, placeholder }: TextareaFieldProps) => {
  const debounceTimer = useRef<NodeJS.Timeout>();
  const { control, formState, setValue } = useFormContext<FormValues>();
  const { errors } = formState;

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel hasErrorStyle={false}>{label}</FormLabel>
          <FormControl>
            <Textarea
              {...field}
              value={field.value}
              rows={4}
              maxRows={10}
              onChange={(e) => {
                clearTimeout(debounceTimer.current);
                debounceTimer.current = setTimeout(() => {
                  setValue(field.name, e.target.value);
                }, debounce);
              }}
              placeholder={placeholder}
            />
          </FormControl>
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => <div className="text-sm text-brand">{message}</div>}
          />
        </FormItem>
      )}
    />
  );
};
