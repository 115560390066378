import { toast } from '@askable/ui/components/ui/sonner';
import { Maybe } from 'generated/graphql';
import { bookingUtils } from 'lib/booking';
import { BOOKING_TYPE, DEFAULT_QUOTA } from 'lib/constants';
import { useForm } from 'react-hook-form';
import { useMutation } from 'urql';
import { RequestCreditsMutation } from './data/RequestCredits.mutation';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@askable/ui/components/ui/form';
import { SelectContent, SelectItem, SelectTrigger, SelectValue, Select } from '@askable/ui/components/ui/select';
import { Input } from '@askable/ui/components/ui/input';
import { RadioGroup, RadioGroupItem } from '@askable/ui/components/ui/radio-group';
import { Textarea } from '@askable/ui/components/ui/textarea';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@askable/ui/components/ui/dialog';
import { Button } from '@askable/ui/components/ui/button';

type RequestCreditsProps = {
  isOpen: boolean;
  onClose: () => void;
  connectedTeamId?: Maybe<string>;
};

type FormDetails = {
  bookingValue: string;
  totalParticipants: string;
  bookingType: string;
  bookingSubType: string;
  remoteVideoCallTool: string;
  sessionType: string;
  sessionDuration: string;
  participantAgreementType: 'standard' | 'custom';
  participantCategory: string;
  comment: string;
};

const RequestCreditsContainer = ({ connectedTeamId, isOpen, onClose }: RequestCreditsProps) => {
  const [{ fetching: loading }, createCreditRequest] = useMutation(RequestCreditsMutation);
  const form = useForm<FormDetails>({
    defaultValues: {
      bookingValue: '2',
      totalParticipants: `${DEFAULT_QUOTA.REMOTE}`,
      bookingType: `${BOOKING_TYPE.REMOTE}`,
      bookingSubType: '1',
      remoteVideoCallTool: 'askableLive',
      sessionType: '1',
      sessionDuration: '30',
      participantAgreementType: 'standard',
      participantCategory: '1',
      comment: '',
    },
  });

  const bookingTypeWatch = form.watch('bookingType');
  const bookingSubTypeWatch = form.watch('bookingSubType');
  const remoteTool = form.watch('remoteVideoCallTool');

  const handleClose = () => {
    form.reset();

    onClose();
  };

  const onSubmit = async ({
    bookingType,
    sessionDuration,
    bookingSubType,
    totalParticipants,
    sessionType,
    comment,
    participantCategory,
    remoteVideoCallTool,
    participantAgreementType,
  }: FormDetails) => {
    try {
      const { error } = await createCreditRequest({
        _team_id: connectedTeamId!,
        credit_request: {
          booking_type: Number(bookingType),
          booking_subtype: Number(bookingSubType),
          total_participants: Number(totalParticipants),
          session_type: Number(sessionType),
          duration: Number(sessionDuration),
          participant_category: Number(participantCategory),
          participant_agreement_type: participantAgreementType,
          comments: comment,
          remote_video_call_tool: remoteVideoCallTool,
        },
      });
      if (error) {
        throw error;
      }

      toast.success('Your credit request has been sent');

      handleClose();
    } catch (e) {
      toast.error('There was an error sending your credit request');
    }
  };

  // Get all the booking types available and exclude Longitudinal studies because they are quite complex to deal with on the request credits for now
  const bookingTypes = bookingUtils.bookingTypes().filter((type) => type.value !== 4);

  const handleBookingTypeChange = (value: string) => {
    const newType = parseInt(value, 10);

    const bookingTypeSetup = bookingTypes.find((item) => {
      if (newType === 32) {
        return item.bookingType === 3 && item.bookingSubType === 2;
      }

      return item.bookingType === newType;
    });

    form.setValue('bookingValue', `${newType}`);
    form.setValue('bookingType', `${bookingTypeSetup?.bookingType!}`);
    form.setValue('bookingSubType', `${bookingTypeSetup?.bookingSubType!}`);
    form.setValue('remoteVideoCallTool', bookingTypeSetup?.bookingType === 2 ? 'askableLive' : '');

    if (bookingTypeSetup?.bookingSubType === 2) {
      form.setValue('participantCategory', '1');
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Request credits</DialogTitle>
        </DialogHeader>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
          <Form {...form}>
            <FormField
              control={form.control}
              name="totalParticipants"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Number of participants</FormLabel>
                  <FormControl>
                    <Input type="number" {...field} defaultValue={field.value} />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="bookingValue"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Research method</FormLabel>
                  <FormControl>
                    <Select value={field.value} onValueChange={handleBookingTypeChange}>
                      <SelectTrigger className="w-full">
                        <SelectValue />
                      </SelectTrigger>
                      <SelectContent className="z-[9999]">
                        {bookingTypes.map((type) => {
                          return (
                            <SelectItem key={type.value} value={`${type.value}`}>
                              {type.label}
                            </SelectItem>
                          );
                        })}
                      </SelectContent>
                    </Select>
                  </FormControl>
                </FormItem>
              )}
            />
            {bookingTypeWatch === '2' ? (
              <FormField
                control={form.control}
                name="remoteVideoCallTool"
                render={({ field }) => {
                  return (
                    <FormItem className="space-y-3">
                      <FormLabel>Remote tool</FormLabel>
                      <FormControl>
                        <RadioGroup
                          defaultValue={field.value}
                          onValueChange={(v) => {
                            if (v === 'askableLive') {
                              form.setValue('sessionType', '1');
                            }
                            field.onChange(v);
                          }}
                          className="flex flex-col space-y-1"
                        >
                          <FormItem className="flex items-center space-x-3 space-y-0">
                            <FormControl>
                              <RadioGroupItem value="askableLive" />
                            </FormControl>
                            <FormLabel className="font-normal">Askable Sessions (recommended)</FormLabel>
                          </FormItem>
                          <FormItem className="flex items-center space-x-3 space-y-0">
                            <FormControl>
                              <RadioGroupItem value="other" />
                            </FormControl>
                            <FormLabel className="font-normal">Other</FormLabel>
                          </FormItem>
                        </RadioGroup>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
            ) : null}
            {remoteTool === 'askableLive' ? (
              <FormField
                control={form.control}
                name="sessionType"
                render={({ field }) => (
                  <FormItem className="space-y-3">
                    <FormLabel>Session type</FormLabel>
                    <FormControl>
                      <RadioGroup
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                        className="flex flex-col space-y-1"
                      >
                        {bookingUtils.sessionTypes().map((session) => {
                          return (
                            <FormItem key={session.value} className="flex items-center space-x-3 space-y-0">
                              <FormControl>
                                <RadioGroupItem value={`${session.value}`} />
                              </FormControl>
                              <FormLabel className="font-normal">{session.label}</FormLabel>
                            </FormItem>
                          );
                        })}
                      </RadioGroup>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            ) : null}

            <FormField
              control={form.control}
              name="sessionDuration"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Session duration</FormLabel>
                  <FormControl>
                    <Select value={field.value} onValueChange={field.onChange}>
                      <SelectTrigger className="w-full">
                        <SelectValue />
                      </SelectTrigger>
                      <SelectContent className="z-[9999]">
                        {bookingUtils
                          .sessionDurations(Number(bookingTypeWatch), Number(bookingSubTypeWatch))
                          ?.map((type) => {
                            return (
                              <SelectItem key={type.value} value={`${type.value}`}>
                                {type.label}
                              </SelectItem>
                            );
                          })}
                      </SelectContent>
                    </Select>
                  </FormControl>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="participantCategory"
              render={({ field }) => (
                <FormItem className="space-y-3">
                  <FormLabel>Participants demographic</FormLabel>
                  <FormControl>
                    <RadioGroup
                      onValueChange={field.onChange}
                      defaultValue={`${field.value}`}
                      className="flex flex-col space-y-1"
                    >
                      {bookingUtils.bookingParticipantCategories().map((session) => {
                        return (
                          <FormItem key={session.value} className="flex items-center space-x-3 space-y-0">
                            <FormControl>
                              <RadioGroupItem value={`${session.value}`} />
                            </FormControl>
                            <FormLabel className="font-normal">{session.label}</FormLabel>
                          </FormItem>
                        );
                      })}
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="participantAgreementType"
              render={({ field }) => (
                <FormItem className="space-y-3">
                  <FormLabel>Legal & Privacy</FormLabel>
                  <FormControl>
                    <RadioGroup
                      onValueChange={field.onChange}
                      defaultValue={`${field.value}`}
                      className="flex flex-col space-y-1"
                    >
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value="standard" />
                        </FormControl>
                        <FormLabel className="font-normal">Standard</FormLabel>
                      </FormItem>
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value="custom" />
                        </FormControl>
                        <FormLabel className="font-normal">Custom</FormLabel>
                      </FormItem>
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="comment"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Request details</FormLabel>
                  <FormControl>
                    <Textarea {...field} defaultValue={field.value} />
                  </FormControl>
                  <FormDescription>
                    Please provide a brief outline of your business and research objectives for this study, scope,
                    output audience, and any other relevant details your administer would need for approving your
                    request (job details, approver, cost centre details etc)
                  </FormDescription>
                </FormItem>
              )}
            />
          </Form>
          <DialogFooter>
            <Button type="submit" isLoading={loading}>
              Submit
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default RequestCreditsContainer;
