import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { Outlet, Navigate, useParams, useLocation, matchPath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { useFeatureFlags } from 'feature-flags';
import { useQuery } from 'urql';
import { UnmoderatedStudy } from 'generated/graphql';
import { StudiesHeader } from 'containers/Studies/containers/StudiesHeader';
import { EmptyState } from './components/EmptyState';
import { SkeletonLoader } from './components/SkeletonLoader';
import Header from 'src/components/common/Header/view';
import { HeaderAds } from 'components/common';
import { PaymentOverDueBanner } from 'components/PaymentOverdueBanner/PaymentOverdueBanner';
import { GetStudy } from 'containers/Studies/data/GetStudy.query';
import { useConnectedClient } from 'context/ConnectedClientContext';

import './Studies.css';

interface StudyContextProps {
  newBlockId: string | null;
  isSaving: boolean;
  study: UnmoderatedStudy;
  studyId: string;
  setNewBlockId: (newBlockId: string | null) => void;
  setIsSaving: (isSaving: boolean) => void;
}

const StudyContext = createContext<StudyContextProps>({} as any);

export const useStudyContext = () => {
  return useContext(StudyContext);
};

// Copied from `components/App/view.tsx`
// TODO: ideally we just render this in one place as the root router layout instead of multiple times
// manually, that way uplifting the component also becomes less involved
function Layout({ children }: { children: ReactNode }) {
  const { details: clientDetails } = useConnectedClient();

  return (
    <div className="flex h-screen flex-col">
      {!clientDetails?.type?.researcher ? <PaymentOverDueBanner teamId={clientDetails?.ConnectedTeam?._id!} /> : null}
      <HeaderAds />
      <Header />

      {children}
    </div>
  );
}

export const StudiesContainer = () => {
  const params = useParams();
  const { t } = useTranslation();
  const { UNMODERATED_STUDY } = useFeatureFlags(['UNMODERATED_STUDY']);

  const { studyId } = params;
  invariant(studyId, 'Study ID is required');

  // Get current section name eg. build
  const location = useLocation();
  const pattern = 'studies/:studyId/:section';
  const match = matchPath(pattern, location.pathname);
  const currentSection = match?.params?.section;

  const [isSaving, setIsSaving] = useState(false);
  const [newBlockId, setNewBlockId] = useState<null | string>(null);

  const [{ data, fetching }] = useQuery({
    query: GetStudy,
    variables: { id: studyId },
    pause: !UNMODERATED_STUDY,
  });

  const value = useMemo(
    () => ({
      newBlockId,
      isSaving,
      study: data?.study as UnmoderatedStudy,
      studyId,
      setNewBlockId,
      setIsSaving,
    }),
    [newBlockId, isSaving, data?.study, studyId, setNewBlockId, setIsSaving],
  );

  if (!UNMODERATED_STUDY) {
    return <Navigate to="/not-found" />;
  }

  if (fetching && !data?.study) {
    return (
      <Layout>
        <SkeletonLoader section={currentSection} />
      </Layout>
    );
  }

  if (!data?.study) {
    return (
      <Layout>
        <EmptyState title={t('sections.studies.studyNotFound')} />
      </Layout>
    );
  }

  return (
    <StudyContext.Provider value={value}>
      <Layout>
        <div className="grid h-full min-h-0 w-full flex-auto grid-rows-[auto_1fr] bg-secondary text-foreground transition-all">
          <StudiesHeader />
          <Outlet />
        </div>
      </Layout>
    </StudyContext.Provider>
  );
};
