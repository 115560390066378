import { useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { FormControl, FormField, FormItem, FormLabel } from '@askable/ui/components/ui/form';
import { Input } from '@askable/ui/components/ui/input';
import type { FormValues } from 'containers/Studies/BuildStudy/containers/BlockEditor';

interface InputFieldProps {
  debounce?: number;
  name: keyof Pick<FormValues, 'title' | 'instructions'>;
  isFocused?: boolean;
  label: string;
  placeholder?: string;
}

export const InputField = ({ debounce = 800, name, isFocused, label, placeholder }: InputFieldProps) => {
  const debounceTimer = useRef<NodeJS.Timeout>();
  const { control, formState, setValue } = useFormContext<FormValues>();
  const { errors } = formState;

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel hasErrorStyle={false}>{label}</FormLabel>
          <FormControl>
            <Input
              defaultValue={field.value}
              onChange={(e) => {
                clearTimeout(debounceTimer.current);
                debounceTimer.current = setTimeout(() => {
                  setValue(field.name, e.target.value);
                }, debounce);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  setValue(field.name, (e.target as HTMLInputElement).value);
                }
              }}
              placeholder={placeholder}
              autoFocus={isFocused}
            />
          </FormControl>
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => <div className="text-sm text-brand">{message}</div>}
          />
        </FormItem>
      )}
    />
  );
};
