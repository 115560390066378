import { graphql } from 'src/graphql';

export const UpdateStudyConfigTaskBlockOrder = graphql(`
  mutation UpdateStudyConfigTaskBlockOrder($input: UpdateStudyConfigTaskBlockOrderInput!) {
    updateStudyConfigTaskBlockOrder(input: $input) {
      _id

      ... on UnmoderatedStudy {
        draft_config {
          _id
          task_blocks {
            _id
            type
            title
            instructions
            is_recording_enabled
          }
        }
      }
    }
  }
`);
