import moment from 'moment';
import momentTimezone from 'moment-timezone';
import _ from 'lodash';
import { utils } from 'lib/utils';
import { teamUtils } from 'lib/teams';
import { localStorage } from 'lib/storage';
// import { mapPolygonsUtils } from 'lib/mapPolygons';
import {
  Credits as CreditsType,
  Booking as BookingType,
  Maybe,
  Booking,
  BookingConfig,
  BookingRating,
  LocationCriteria,
  Location,
} from 'generated/graphql';

import { ClipboardList, ContactRound, MessageSquareText, MousePointer, Repeat2, Video } from 'lucide-react';

import {
  REQUIRED_DEVICE_TYPE,
  BOOKING_CONFIG_PARTICIPANT_AGREEMENT_TYPE,
  SUBMISSION_AGREEMENT_STATUS,
  BOOKING_TYPE,
  BOOKING_STATUS,
  LOCATIONS_KEYS,
} from './constants';
import { creditUtils } from './credits';
import { CountryCode } from 'libphonenumber-js';

type DeviceType = (typeof REQUIRED_DEVICE_TYPE)[keyof typeof REQUIRED_DEVICE_TYPE];

const bookingUtils = {
  confirmedParticipants: [],
  /**
   * Gets the currently selected device type on the booking object
   * @param {Object} booking - Booking object
   * @param {number} defaultValue - Default value if none is selected @default REQUIRED_DEVICE_TYPE.DESKTOP
   * @returns number
   */
  getSelectedDevice: (booking: any, defaultValue: DeviceType = REQUIRED_DEVICE_TYPE.DESKTOP) => {
    return _.get(booking, 'config.online_task.required_device[0]', defaultValue);
  },
  /**
   * Checks if the booking type is included in a list of booking types
   * @param {Object} booking - Booking object
   * @param {Array} types - lib/constants - BOOKING_TYPE
   * @returns boolean
   */
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'bookingType' implicitly has an 'a... Remove this comment to see the full error message
  includesBookingType: ({ type: bookingType }, types: any) => {
    return types.includes(bookingType);
  },
  isDemo: (booking: any) => _.get(booking, 'config.demo'),
  getTotalAmount(params: any) {
    // Params
    //   team (Object)
    //   booking (Object)
    //   credit packs (Array)
    const creditsRemaining = teamUtils.getCredits(params.team);
    const creditsPerParticipant = bookingUtils.getCreditsPerParticipant(params.booking);
    const creditsToBeUsed = Math.ceil(params.booking.total_participants * creditsPerParticipant);
    const creditsToBuy = creditsToBeUsed - creditsRemaining;
    // Price per participant depends on the number of credits needed to being bought
    const pricePerParticipant = bookingUtils.getPricePerParticipant(params.creditPacks, creditsToBuy);
    if (!pricePerParticipant) {
      return undefined;
    }
    const totalAmount = (creditsToBuy * pricePerParticipant).toFixed(2);

    return parseFloat(totalAmount);
  },
  validateBookingSetup(booking: any) {
    if (!booking.name) return true;
    // @ts-expect-error ts-migrate(2365) FIXME: Operator '>=' cannot be applied to types 'boolean'... Remove this comment to see the full error message
    if (!booking.total_participants >= 1) return true;
    if (bookingUtils.isLongitudinal(booking)) {
      return !(
        _.get(booking, 'config.longitudinal_study.participant_workload.time') >= 1 &&
        _.get(booking, 'config.longitudinal_study.period.time') >= 1 &&
        _.get(booking, 'config.longitudinal_study.period.frequency') >=
          _.get(booking, 'config.longitudinal_study.participant_workload.frequency')
      );
    }
  },
  validateAudienceSetup() {
    return false;
  },
  validateSessionTimesSetup(booking: any) {
    const doesHaveSlotsNeeded = this.validateSlotsNeeded(booking);
    const doesHaveAValidStartDate = this.validateSessionStartDate(booking);
    const doesHaveAValidRemoteModeratedOrFaceToFaceDate = this.validateRemoteModeratedOrFaceToFaceDate(booking);

    return doesHaveSlotsNeeded && doesHaveAValidStartDate && doesHaveAValidRemoteModeratedOrFaceToFaceDate;
  },
  validateAdditionalInfoSetup(booking: any) {
    // Validations for face to face
    if (bookingUtils.isInPerson(booking)) {
      return !_.get(booking, 'config.location.name') || !_.get(booking, 'config.information.brief_summary');
    }
    // Validations for online survey
    if (bookingUtils.isOnlineTask(booking) || bookingUtils.isLongitudinal(booking)) {
      return !_.get(booking, 'config.information.brief_summary') || !this.validateOnlineSurveyDate(booking);
    }
    return (
      !_.get(booking, 'config.information.brief_summary') ||
      !_.get(booking, 'config.contact.UserOrganiser.contact.phone.mobile')
    );
  },
  validateSlotsNeeded(booking: any) {
    if (booking.config.session.type === 1 && (bookingUtils.isRemote(booking) || bookingUtils.isInPerson(booking))) {
      if (booking.session.length < booking.total_participants) {
        return false;
      }
      return true;
    }
    if (booking.session.length === 0) {
      return false;
    }
    return true;
  },
  validateSessionStartDate(booking: any) {
    // Method to validate whether or not the session start date is valid
    //  It should not test if the booking is already live
    if (bookingUtils.isActive(booking)) return true;

    const orderedArray = utils.orderArrayByDate(booking.session, 'start');
    if (orderedArray.length === 0) return false;
    const minDate = moment(
      utils.addWeekdaysToTimestamp(moment(moment().format('DD/MM/YYYY'), 'DD/MM/YYYY').valueOf(), 1),
    );
    return moment(orderedArray[0].start).isSameOrAfter(minDate);
  },
  validateLinkToTaskSetup(booking: any) {
    if (_.size(_.get(booking, 'config.online_task.links')) === 0) return true;
    if (_.size(_.filter(_.get(booking, 'config.online_task.links'), (onlineTask: any) => !onlineTask.checked)) > 0) {
      return true;
    }

    if (bookingUtils.isBookingAutomated(booking) && _.size(_.get(booking, 'config.online_task.links')) > 1) {
      return true;
    }

    return false;
  },
  validateOnlineSurveyDate(booking: any) {
    // Longitudinal study has the same logic as the online task
    if (bookingUtils.isOnlineTask(booking) || bookingUtils.isLongitudinal(booking)) {
      if (booking.session.length !== 1) return false;
      if (booking.session[0].start !== booking.session[0].end) return false;
      if (!this.validateSessionStartDate(booking)) return false;
    }
    return true;
  },
  validateRemoteModeratedOrFaceToFaceDate(booking: any) {
    if (bookingUtils.isRemote(booking) || bookingUtils.isInPerson(booking)) {
      if (booking.session.length === 0) return false;
      if (booking.session[0].start === booking.session[0].end) return false;
    }
    return true;
  },
  getErrorMessageBookingSetup(booking: any) {
    const result = [];
    if (booking.total_participants < 1) {
      result.push({
        message: `Minimum of 1 participant required. You have selected ${booking.total_participants || 0} participants`,
        type: 'error',
      });
    }
    return result;
  },
  getErrorMessageLongitudinalTimes(booking: any) {
    const result = [];
    // @ts-expect-error ts-migrate(2365) FIXME: Operator '>=' cannot be applied to types 'boolean'... Remove this comment to see the full error message
    if (!_.get(booking, 'config.longitudinal_study.participant_workload.time') >= 1) {
      result.push({
        message: 'Your workload time is not valid.',
        type: 'error',
      });
    }
    // @ts-expect-error ts-migrate(2365) FIXME: Operator '>=' cannot be applied to types 'boolean'... Remove this comment to see the full error message
    if (!_.get(booking, 'config.longitudinal_study.period.time') >= 1) {
      result.push({
        message: 'Your study period is not valid.',
        type: 'error',
      });
    }
    if (
      _.get(booking, 'config.longitudinal_study.period.frequency') <
      _.get(booking, 'config.longitudinal_study.participant_workload.frequency')
    ) {
      result.push({
        message: 'Period frequency must be greater than workload frequency.',
        type: 'error',
      });
    }
    return result;
  },
  getErrorMessageSessionTimes(booking: any) {
    const slotsNeeded = bookingUtils.isFocusGroup(booking) ? 1 : booking.total_participants;
    const result = [];
    if (!this.validateSlotsNeeded(booking) && (bookingUtils.isInPerson(booking) || bookingUtils.isRemote(booking))) {
      if (bookingUtils.isFocusGroup(booking) || slotsNeeded === 1) {
        result.push({
          message: `${slotsNeeded} slot is needed. You haven't created any slots yet.`,
          type: 'error',
        });
      } else if (booking.session.length === 0) {
        result.push({
          message: `${slotsNeeded} slots are needed. You haven't created any slots yet.`,
          type: 'error',
        });
      } else {
        result.push({
          message: `${slotsNeeded} slots are needed. You have created only ${booking.session.length} slots.`,
          type: 'error',
        });
      }
    }
    const doesHaveAValidStartDate = this.validateSessionStartDate(booking);
    const doesHaveAValidRemoteModeratedOrFaceToFaceDate = this.validateRemoteModeratedOrFaceToFaceDate(booking);
    if (bookingUtils.isInPerson(booking) || bookingUtils.isRemote(booking)) {
      if (!doesHaveAValidStartDate || !doesHaveAValidRemoteModeratedOrFaceToFaceDate) {
        result.push({
          message: 'Your session times are no longer valid.',
          type: 'error',
        });
      }
    }
    return result;
  },
  getErrorMessageAdditionalInfo(booking: any) {
    const result = [];
    if (bookingUtils.isInPerson(booking)) {
      if (!booking.config.location.name) {
        result.push({
          message: 'No location detected. You are required to select a place.',
          type: 'error',
        });
      }
    }
    if (bookingUtils.isOnlineTask(booking) || bookingUtils.isLongitudinal(booking)) {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
      if (_.size(booking, 'session') === 0) {
        result.push({
          message: 'You need to provide a valid completion date for your survey',
          type: 'error',
        });
      }
      if (!this.validateSessionStartDate(booking)) {
        result.push({
          message: 'Completion date should not be in the past',
          type: 'error',
        });
      }
    }
    if (!_.get(booking, 'config.information.brief_summary')) {
      result.push({
        message: 'No brief summary detected. You should give a brief summary to participants.',
        type: 'error',
      });
    }
    if (!_.get(booking, 'config.contact.UserOrganiser.contact.phone.mobile')) {
      result.push({
        message: `${_.get(booking, 'config.contact.UserOrganiser.meta.identity.firstname', 'Organiser')}'s phone number is missing.`,
        type: 'warning',
      });
    }
    return result;
  },
  getErrorMessageLinkToTask(booking: any) {
    const result = [];
    if (_.size(_.get(booking, 'config.online_task.links')) === 0) {
      result.push({
        message: 'You need to provide at least a primary survey link.',
        type: 'error',
      });
    }
    if (_.size(_.filter(_.get(booking, 'config.online_task.links'), (onlineTask: any) => !onlineTask.checked)) > 0) {
      result.push({
        message: 'You need to mark that all links have been checked.',
        type: 'error',
      });
    }
    if (bookingUtils.isBookingAutomated(booking) && _.size(_.get(booking, 'config.online_task.links')) > 1) {
      result.push({
        message: 'Only one link is allowed on automated online tasks',
        type: 'error',
      });
    }
    return result;
  },
  sessionTypes() {
    return [
      {
        label: '1 on 1 interviews',
        value: 1,
      },
      {
        label: 'Focus group',
        value: 2,
      },
    ];
  },
  sessionDurations(bookingType: number | null, bookingSubType: number | null) {
    // Session duration changes depending on the booking type
    switch (bookingType) {
      case 1: // Face to Face
        return [
          {
            label: '30 mins',
            value: 30,
            creditsPerParticipant: 80,
          },
          {
            label: '45 mins',
            value: 45,
            creditsPerParticipant: 100,
          },
          {
            label: '60 mins',
            value: 60,
            creditsPerParticipant: 100,
          },
          {
            label: '1 hr 15 mins',
            value: 75,
            creditsPerParticipant: 150,
          },
          {
            label: '1 hr 30 mins',
            value: 90,
            creditsPerParticipant: 150,
          },
          {
            label: '2 hrs',
            value: 120,
            creditsPerParticipant: 150,
          },
        ];
      case 2: // Remote Video Call
        return [
          {
            label: '30 mins',
            value: 30,
            creditsPerParticipant: 64,
          },
          {
            label: '45 mins',
            value: 45,
            creditsPerParticipant: 80,
          },
          {
            label: '60 mins',
            value: 60,
            creditsPerParticipant: 80,
          },
          {
            label: '1 hr 15 mins',
            value: 75,
            creditsPerParticipant: 120,
          },
          {
            label: '1 hr 30 mins',
            value: 90,
            creditsPerParticipant: 120,
          },
          {
            label: '2 hrs',
            value: 120,
            creditsPerParticipant: 120,
          },
        ];
      case 3: // Online Task
        if (bookingSubType === 1) {
          return [
            {
              label: '5 mins',
              value: 5,
              creditsPerParticipant: 100,
            },
            {
              label: '10 mins',
              value: 10,
              creditsPerParticipant: 100,
            },
            {
              label: '15 mins',
              value: 15,
              creditsPerParticipant: 100,
            },
            {
              label: '30 mins',
              value: 30,
              creditsPerParticipant: 150,
            },
            {
              label: '45 mins',
              value: 45,
              creditsPerParticipant: 225,
            },
            {
              label: '60 mins',
              value: 60,
              creditsPerParticipant: 300,
            },
          ];
        }
        return [
          {
            label: '5 mins',
            value: 5,
            creditsPerParticipant: 50,
          },
          {
            label: '10 mins',
            value: 10,
            creditsPerParticipant: 50,
          },
          {
            label: '15 mins',
            value: 15,
            creditsPerParticipant: 75,
          },
          {
            label: '30 mins',
            value: 30,
            creditsPerParticipant: 100,
          },
          {
            label: '45 mins',
            value: 45,
            creditsPerParticipant: 150,
          },
          {
            label: '60 mins',
            value: 60,
            creditsPerParticipant: 200,
          },
        ];
      default:
        break;
    }
  },
  bookingHandpickOptions() {
    return [
      {
        value: true,
        label: 'Hand picking',
      },
      {
        value: false,
        label: 'Automated',
      },
    ];
  },
  bookingRecruitmentType() {
    return [
      {
        value: false,
        label: 'Askable Panel',
        title: 'Recruit from Askable panel',
      },
      {
        value: true,
        label: 'Your audience',
        title: 'Recruit from your own audience by sending a link',
      },
    ];
  },
  bookingParticipantCategories() {
    return [
      {
        value: 1,
        label: 'Standard',
        short: '',
        card_title: 'Standard Incentives',
        content: [{ label: 'General panel' }, { label: 'Students' }, { label: 'Easy recruitment specs' }],
        image: '/booking/illustrations/oneCoin.svg',
        type: 'consumer',
      },
      {
        value: 2,
        label: 'Premium',
        short: '',
        card_title: 'Premium Incentives',
        content: [{ label: 'Executives' }, { label: 'Business owners' }, { label: 'Hard recruitment specs' }],
        image: '/booking/illustrations/manyCoins.svg',
        type: 'pro',
      },
    ];
  },
  bookingStatus() {
    return [
      {
        value: 0,
        label: 'Draft',
        class: 'statusDot grey',
        filterAvailable: true,
      },
      {
        value: 1,
        label: 'Active',
        class: 'statusDot green',
        filterAvailable: true,
      },
      {
        value: 2,
        label: 'Pending Review',
        class: 'statusDot orange',
      },
      {
        value: 3,
        label: 'In Review',
        class: 'statusDot orange',
        filterAvailable: true,
      },
      {
        value: 4,
        label: 'Rejected',
        class: 'statusDot red',
      },
      {
        value: 5,
        label: 'Completed',
        class: 'statusDot black',
        filterAvailable: true,
      },
      {
        value: 6,
        label: 'Pending Payment',
        class: 'statusDot orange',
      },
      {
        value: 7,
        label: 'Archived',
        class: 'statusDot black',
        filterAvailable: true,
      },
    ];
  },
  bookingTypes(booking?: any) {
    let creditsPerParticipant = 1;
    let participantTypeShortLabel = '';
    if (booking) {
      creditsPerParticipant = bookingUtils.getCreditsPerParticipant(booking);
      participantTypeShortLabel = bookingUtils.getBookingParticipantCategoryShortLabel(booking);
    }
    return [
      {
        label: 'Video Call',
        sublabel: `(${creditsPerParticipant} credit${creditsPerParticipant > 1 ? 's' : ''} per ${participantTypeShortLabel} participant)`,
        completeOrderLabel: `${creditsPerParticipant} credit${creditsPerParticipant > 1 ? 's' : ''} each`,
        value: 2,
        bookingType: 2,
        participantsPerCredit: 1,
        defaultIcon: <Video className="h-4 w-4" />,
        defaultIconString: '<img alt="Remote Video Call Icon" src="/icons/remote_video_call.svg" />',
        disabled: false,
      },
      {
        label: 'In-person',
        sublabel: `(${creditsPerParticipant} credit${creditsPerParticipant > 1 ? 's' : ''} per ${participantTypeShortLabel} participant)`,
        completeOrderLabel: `${creditsPerParticipant} credit${creditsPerParticipant > 1 ? 's' : ''} each`,
        value: 1,
        bookingType: 1,
        participantsPerCredit: 1,
        defaultIcon: <ContactRound className="h-4 w-4" color="#009B19" />,
        defaultIconString: '<img alt="In Person Icon" src="/icons/in_person.svg" />',
        disabled: false,
      },
      {
        label: 'Online Task',
        sublabel: `(${creditsPerParticipant} credit${creditsPerParticipant > 1 ? 's' : ''} per ${participantTypeShortLabel} response)`,
        completeOrderLabel: `${creditsPerParticipant} credit${creditsPerParticipant > 1 ? 's' : ''} per  response`,
        value: 3,
        bookingType: 3,
        bookingSubType: 1,
        participantsPerCredit: 5,
        defaultIcon: <MousePointer className="h-4 w-4" />,
        defaultIconString: '<img alt="Online Task Icon" src="/icons/online_task.svg" />',
        disabled: false,
      },
      {
        label: 'Survey',
        sublabel: `(${creditsPerParticipant} credit${creditsPerParticipant > 1 ? 's' : ''} per ${participantTypeShortLabel} response)`,
        completeOrderLabel: `${creditsPerParticipant} credit${creditsPerParticipant > 1 ? 's' : ''} per  response`,
        value: 32, // 32 = 3 from type and 2 from subtype
        bookingType: 3,
        bookingSubType: 2,
        participantsPerCredit: 5,
        defaultIcon: <ClipboardList className="h-4 w-4" color="#BB2AFF" />,
        defaultIconString: '<img alt="Survey Icon" src="/icons/survey.svg" />',
        disabled: false,
      },
      {
        label: 'AI moderated',
        sublabel: `(${creditsPerParticipant} credit${creditsPerParticipant > 1 ? 's' : ''} per ${participantTypeShortLabel} response)`,
        completeOrderLabel: `${creditsPerParticipant} credit${creditsPerParticipant > 1 ? 's' : ''} per  response`,
        value: 33, // 32 = 3 from type and 2 from subtype
        bookingType: 3,
        bookingSubType: 3,
        participantsPerCredit: 5,
        defaultIcon: <MessageSquareText className="h-4 w-4" />,
        defaultIconString: '<img alt="AI Moderated Icon" src="/icons/survey.svg" />',
        disabled: false,
      },
      {
        label: 'Longitudinal Study',
        sublabel: `(${creditsPerParticipant} credit${creditsPerParticipant > 1 ? 's' : ''} per ${participantTypeShortLabel} participant)`,
        completeOrderLabel: `${creditsPerParticipant} credit${creditsPerParticipant > 1 ? 's' : ''} each`,
        value: 4,
        bookingType: 4,
        participantsPerCredit: 1,
        defaultIcon: <Repeat2 className="h-4 w-4" />,
        defaultIconString: '<img alt="Longitudinal Study Icon" src="/icons/longitudinal_study.svg" />',
        disabled: false,
      },
    ];
  },
  getBookingParticipantCategoryShortLabel(booking: any) {
    // Function to retrieve a label to be displayed to the user depending if he's chosen general of professionals
    return (
      _.get(
        _.find(
          bookingUtils.bookingParticipantCategories(),
          (item: any) => item.value === _.get(booking, 'config.participant_category'),
        ),
        'short',
      ) || ''
    );
  },
  bookingPaymentMethods() {
    return [
      {
        value: 1,
        label: 'Credit Card',
      },
      {
        value: 2,
        label: 'Invoice',
      },
    ];
  },
  demographicFilterOptions() {
    return [
      {
        value: 1,
        label: 'Age',
        image: '/icons/ageIcon.svg',
        id: 'meta_identity_birthday_year',
      },
      {
        value: 2,
        label: 'Gender',
        image: '/icons/genderIcon.svg',
        id: 'meta_identity_gender',
      },
      {
        value: 3,
        label: 'Language fluency',
        image: '/icons/languageFluencyIcon.svg',
        id: 'meta_identity_languages_english_speak',
      },
      {
        value: 4,
        label: 'Marital status',
        image: '/icons/maritalStatusIcon.svg',
        id: 'meta_family_status',
      },
      {
        value: 5,
        label: 'Education level',
        image: '/icons/educationLevelIcon.svg',
        id: 'meta_education',
      },
      {
        value: 6,
        label: 'Employment status',
        image: '/icons/employmentStatusIcon.svg',
        id: 'meta_work_status',
      },
      // {
      //     value: 7,
      //     label: 'Language',
      //     image: '/icons/languageIcon.svg',
      //     id: 'meta_identity_languages'
      // },
      // {
      //     value: 8,
      //     label: 'Education field',
      //     image: '/icons/educationFieldIcon.svg',
      //     id: 'meta_education_field'
      // },
      // {
      //     value: 9,
      //     label: 'Individual income',
      //     image: '/icons/individualIncomeIcon.svg',
      //     id: 'meta_individual_income'
      // },
      // {
      //     value: 10,
      //     label: 'Household income',
      //     image: '/icons/householdIncomeIcon.svg',
      //     id: 'meta_family_income'
      // },
      // {
      //     value: 11,
      //     label: 'Tech savviness',
      //     image: '/icons/techSavvinessIcon.svg',
      //     id: 'meta_tech_savviness'
      // },
      // {
      //     value: 12,
      //     label: 'Home ownership',
      //     image: '/icons/homeOwnershipIcon.svg',
      //     id: 'meta_home_ownership'
      // },
      // {
      //     value: 13,
      //     label: 'Purchasing behaviour',
      //     image: '/icons/purchasingBehaviourIcon.svg',
      //     id: 'meta_purchasing_behaviour'
      // },
      // {
      //     value: 14,
      //     label: 'Children',
      //     image: '/icons/childrenIcon.svg',
      //     id: 'meta_children'
      // },
      {
        value: 15,
        label: 'Business ownership',
        image: '/icons/businessOwnershipIcon.svg',
        id: 'meta_work_employment_type',
        type: 'professionals',
      },
      {
        value: 16,
        label: 'Employment industry',
        image: '/icons/employmentIndustryIcon.svg',
        id: 'meta_work_industry',
        type: 'professionals',
      },
    ];
  },
  englishProficiencyTypes() {
    return [
      {
        id: 'user.meta.identity.languages.english.speak.native',
        label: 'Native',
        index: 0,
      },
      {
        id: 'user.meta.identity.languages.english.speak.fluent',
        label: 'Fluent',
        index: 1,
      },
      {
        id: 'user.meta.identity.languages.english.speak.conversational',
        label: 'Conversational',
        index: 2,
      },
      {
        id: 'user.meta.identity.languages.english.speak.beginner',
        label: 'Beginner',
        index: 3,
      },
    ];
  },
  workStatusTypes() {
    return [
      {
        id: 'any',
        label: 'Any occupation status',
      },
      {
        id: 'user.meta.work.status.fulltime',
        label: 'Full time work',
      },
      {
        id: 'user.meta.work.status.parttime',
        label: 'Part time / casual work',
      },
      {
        id: 'user.meta.work.status.fulltime_student',
        label: 'Full time student',
      },
      {
        id: 'user.meta.work.status.homeduties',
        label: 'Full time home duties',
      },
      {
        id: 'user.meta.work.status.retired',
        label: 'Retired',
      },
      {
        id: 'user.meta.work.status.unemployed',
        label: 'Unemployed',
      },
    ];
  },
  workEmploymentType() {
    return [
      {
        id: 'any',
        label: 'Any executive level',
      },
      {
        id: 'user.meta.work.employment_type',
        value: '1',
        label: 'Employee',
      },
      {
        id: 'user.meta.work.employment_type',
        value: '2',
        label: 'Sole trader / freelancer',
      },
      {
        id: 'user.meta.work.employment_type',
        value: '3',
        label: 'Business Owner / Founder',
      },
    ];
  },
  genderTypes() {
    return [
      {
        label: 'All genders',
        value: 1,
      },
      {
        label: 'Males only',
        value: 2,
      },
      {
        label: 'Females only',
        value: 3,
      },
      {
        label: 'Non-binary only',
        value: 0,
      },
    ];
  },
  filterGenderTypes() {
    return [
      { label: 'Male', value: 'male' },
      { label: 'Female', value: 'female' },
      { label: 'Non-binary', value: 'non-binary' },
    ];
  },
  remoteSetupCheck() {
    return [
      {
        label: 'Completed',
        value: 'Completed',
      },
      {
        label: 'Not Completed',
        value: 'Not Completed',
      },
    ];
  },
  agreementStatus() {
    return [
      {
        label: 'Sent',
        value: SUBMISSION_AGREEMENT_STATUS.SENT,
      },
      {
        label: 'Declined',
        value: SUBMISSION_AGREEMENT_STATUS.DECLINED,
      },
      {
        label: 'Viewed',
        value: SUBMISSION_AGREEMENT_STATUS.VIEWED,
      },
      {
        label: 'Signed',
        value: SUBMISSION_AGREEMENT_STATUS.COMPLETED,
      },
    ];
  },
  maritalStatusTypes() {
    return [
      {
        id: 'any',
        label: 'Any marital status',
      },
      {
        id: 'user.meta.family.status.single',
        label: 'Single',
      },
      {
        id: 'user.meta.family.status.married',
        label: 'Married / Defacto',
      },
      {
        id: 'user.meta.family.status.divorced',
        label: 'Divorced',
      },
      {
        id: 'user.meta.family.status.widowed',
        label: 'Widowed',
      },
    ];
  },
  educationLevels() {
    return [
      {
        id: 'any',
        label: 'Any education level',
      },
      {
        id: 'user.meta.education.highschool',
        label: 'Finished high school',
      },
      {
        id: 'user.meta.education.some_university',
        label: 'Some university',
      },
      {
        id: 'user.meta.education.undergraduate',
        label: 'Undergraduate',
      },
      {
        id: 'user.meta.education.postgraduate',
        label: 'Postgraduate',
      },
      {
        id: 'user.meta.education.apprenticeship',
        label: 'Apprenticeship',
      },
    ];
  },
  exclusionTypes() {
    return [
      {
        full_description: 'Exclude people who’ve attended my previous sessions in the last 3 months',
        label: 'In the last 3 months',
        value: 3,
      },
      {
        full_description: 'Exclude people who’ve attended my previous sessions in the last 6 months',
        label: 'In the last 6 months',
        value: 6,
      },
      {
        full_description: 'Exclude people who’ve attended my previous sessions in the last 12 months',
        label: 'In the last 12 months',
        value: 12,
      },
      {
        full_description: "Don't exclude anyone",
        label: "Don't exclude anyone",
        value: null,
      },
    ];
  },
  bookingInContextResearchTypes() {
    return [
      {
        label: "I'm doing the interviews at my office or space",
        value: false,
      },
      {
        label: "I'm doing in-context research",
        value: true,
      },
    ];
  },
  bookingInContextResearchLocationTypes() {
    return [
      {
        label: "Participant's home",
        description: "I'd like to go to the participant's home",
        header_description: "Participant's home address",
        value: 1,
      },
      {
        label: "Participant's office, workplace or business location",
        description: "I'd like to go to the participant's office, workplace or business location",
        header_description: "Participant's workplace address",
        value: 2,
      },
      {
        label: 'Participant can choose a neutral place (e.g. Cafe, Library) near them',
        description: "I'd like the participants to choose a neutral place (e.g. Cafe) near them",
        header_description: "Participant's chosen location",
        value: 3,
      },
    ];
  },
  bookingInContextResearchTravelTimeTypes() {
    return [
      {
        label: '10 mins',
        value: 10,
      },
      {
        label: '15 mins',
        value: 15,
      },
      {
        label: '20 mins',
        value: 20,
      },
      {
        label: '25 mins',
        value: 25,
      },
      {
        label: '30 mins',
        value: 30,
      },
      {
        label: '45 mins',
        value: 45,
      },
      {
        label: '1 hour',
        value: 60,
      },
    ];
  },
  bookingOnlineTaskRequiredDeviceTypes() {
    return [
      {
        label: 'Desktop only',
        value: 1,
      },
      {
        label: 'Mobile only',
        value: 2,
      },
      {
        label: 'Any device',
        value: '',
      },
    ];
  },
  bookingOnlineTaskRequiredFeatureTypes() {
    return [
      {
        id: 1,
        label: 'Camera required',
        value: 1,
      },
      {
        id: 2,
        label: 'Microphone required',
        value: 2,
      },
    ];
  },
  bookingRemoteVideoCallDeviceOptions() {
    return [
      {
        label: 'Laptop only',
        value: 1,
      },
      {
        label: 'Any device',
        value: null,
      },
    ];
  },
  bookingRemoteVideoCallToolOptions() {
    return [
      {
        label: 'Askable Sessions (Recommended)',
        value: 'askableLive',
        logoName: 'askableSessionsLogo.svg',
      },
      {
        label: 'Zoom',
        value: 'zoom',
        logoName: 'zoomLogo.png',
      },
      {
        label: 'Lookback',
        value: 'lookback',
        logoName: 'lookbackLogo.svg',
      },
      {
        label: 'Microsoft Teams',
        value: 'microsoftTeams',
        logoName: 'microsoftTeamsLogo.png',
      },
      {
        label: 'Validately',
        value: 'validately',
        logoName: 'validatelyLogo.png',
      },
      {
        label: 'Loop11',
        value: 'loop11',
        logoName: 'loop11Logo.png',
      },
      {
        label: 'Google Meet',
        value: 'googleMeet',
        logoName: 'googleMeetLogo.svg',
      },
      {
        label: 'Cisco WebEx',
        value: 'ciscoWebex',
        logoName: 'ciscoWebexLogo.svg',
      },
      {
        label: 'Hearsay',
        value: 'hearsay',
        logoName: 'hearsayLogo.svg',
      },
      {
        label: 'Other',
        value: 'other',
        logoName: '',
      },
    ];
  },
  bookingSurveyToolOptions() {
    return [
      {
        label: 'Optimal Workshop',
        value: 'optimalWorkshop',
        logoName: 'optimalWorkshopLogo.png',
      },
      {
        label: 'SurveyMonkey',
        value: 'surveyMonkey',
        logoName: 'surveyMonkeyLogo.svg',
      },
      {
        label: 'Typeform',
        value: 'typeform',
        logoName: 'typeformLogo.svg',
      },
      {
        label: 'Google Forms',
        value: 'googleForms',
        logoName: 'googleFormsLogo.svg',
      },
      {
        label: 'SurveyGizmo',
        value: 'surveyGizmo',
        logoName: 'surveyGizmoLogo.svg',
      },
      {
        label: 'Other',
        value: 'other',
        logoName: '',
      },
    ];
  },
  bookingOnlineTaskToolOptions() {
    return [
      {
        label: 'Optimal Workshop',
        value: 'optimalWorkshop',
        logoName: 'optimalWorkshopLogo.png',
      },
      {
        label: 'Loop11',
        value: 'loop11',
        logoName: 'loop11Logo.png',
      },
      {
        label: 'UserZoom',
        value: 'userZoom',
        logoName: 'userZoomLogo.svg',
      },
      {
        label: 'Validately',
        value: 'validately',
        logoName: 'validatelyLogo.png',
      },
      {
        label: 'Dscout',
        value: 'dscout',
        logoName: 'dscoutLogo.svg',
      },
      {
        label: 'Qualtrics',
        value: 'qualtrics',
        logoName: 'qualtricsLogo.svg',
      },
      {
        label: 'UsabilityHub',
        value: 'usabilityHub',
        logoName: 'usabilityHubLogo.svg',
      },
      {
        label: 'Lookback',
        value: 'lookback',
        logoName: 'lookbackLogo.svg',
      },
      {
        label: 'Maze',
        value: 'maze',
        logoName: 'mazeLogo.svg',
      },
      {
        label: 'Other',
        value: 'other',
        logoName: '',
      },
    ];
  },
  showCompanyOptions() {
    return [
      {
        label: 'Show Information',
        value: true,
      },
      {
        label: 'Hide Information',
        value: false,
      },
    ];
  },
  incentiveValues() {
    return [
      {
        label: 'None',
        value: 0,
      },
      {
        label: "I'll handle incentives",
        value: null,
      },
      {
        label: '10',
        value: 10,
      },
      {
        label: '20',
        value: 20,
      },
      {
        label: '30',
        value: 30,
      },
      {
        label: '40',
        value: 40,
      },
      {
        label: '50',
        value: 50,
      },
      {
        label: '60',
        value: 60,
      },
      {
        label: '70',
        value: 70,
      },
      {
        label: '80',
        value: 80,
      },
      {
        label: '90',
        value: 90,
      },
      {
        label: '100',
        value: 100,
      },
    ];
  },
  bookingOnlineTaskToolUsed(booking: any) {
    // Function to render what we should display in the UI
    if (_.get(booking, 'config.online_task.tool')) {
      return utils.getLabelFromArray(
        bookingUtils.bookingOnlineTaskToolOptions(),
        _.get(booking, 'config.online_task.tool'),
      );
    }
    return 'Other tool';
  },
  bookingRemoteCallToolUsed(booking: BookingType): string {
    // Function to render what we should display in the UI
    if (booking.config?.remote?.tool) {
      return _.replace(
        utils.getLabelFromArray(bookingUtils.bookingRemoteVideoCallToolOptions(), booking.config.remote.tool),
        ' (Recommended)',
        '',
      );
    }
    return 'Other tool';
  },
  bookingRemoteVideoCallTitle(booking: any) {
    // Function to render what we should display in the UI
    if (_.get(booking, 'config.remote.tool')) {
      return `Remote testing tool: ${utils.getLabelFromArray(bookingUtils.bookingRemoteVideoCallToolOptions(), _.get(booking, 'config.remote.tool'))}`;
    }
    return 'Remote testing tool: 3rd party remote testing software';
  },
  getCurrentTimezone() {
    return momentTimezone.tz.guess();
  },
  regenerateSessionTimesBasedOnTimezone(sessions: any, newTimezone: any, currentTimezone: any) {
    return sessions.map((session: any) => {
      return {
        _id: session._id,
        start: utils.convertTimestampToDifferentTimezone(session.start, newTimezone, currentTimezone),
        end: utils.convertTimestampToDifferentTimezone(session.end, newTimezone, currentTimezone),
        slot_max: session.slot_max,
        slot_min: session.slot_min,
      };
    });
  },
  getCriteriaValue(params: any, booking: any) {
    const { criteria } = booking.config;
    if (criteria && criteria[params.parent]) {
      const result = criteria[params.parent].filter((item: any) => {
        if (params.operator) return item.field === params.field && item.operator === params.operator;
        if (params.value) return item.field === params.field && item.value === params.value;
        return item.field === params.field;
      })[0];
      if (result) return result.value;
    }
    return null;
  },
  getFakeNumberOfParticipantsContacted(total_participants: any) {
    // Logic:
    //  Multiplies the total number of participants by 20
    //  Gets the total number and sums with a round of total participant times pi
    return total_participants * 20 + Math.round(total_participants * 3.14);
  },
  getAgeRange(booking: any) {
    const bookingMaxAge =
      bookingUtils.getCriteriaValue(
        { parent: 'meta_identity_birthday_year', field: 'user.meta.identity.birthday.timestamp', operator: 'gt' },
        booking,
      ) || moment().subtract(199, 'y').valueOf();
    const bookingMinAge =
      bookingUtils.getCriteriaValue(
        { parent: 'meta_identity_birthday_year', field: 'user.meta.identity.birthday.timestamp', operator: 'lt' },
        booking,
      ) || moment().subtract(18, 'y').valueOf();
    const minAge = utils.getCurrentYear() - utils.getYearFromTimestamp(bookingMinAge);
    let maxAge = bookingMaxAge ? utils.getCurrentYear() - utils.getYearFromTimestamp(bookingMaxAge) : 100;
    if (maxAge === 199) maxAge = 100;
    const ageRange = utils.getRangeNumbers(minAge, maxAge);
    ageRange.push('100+');
    return ageRange;
  },
  getPricePerParticipant(creditPacks: CreditsType[], numCredits = 1) {
    const creditPackPrice = creditUtils.getCreditPackPrice(creditPacks, numCredits);
    return creditPackPrice?.value || undefined;
  },
  getCreditsForSessionDuration(booking: any) {
    if (!_.has(booking, 'config.session.duration')) return 100;
    const creditsPerParticipantSessionDuration = _.find(
      bookingUtils.sessionDurations(_.get(booking, 'type'), _.get(booking, 'config.online_task.type')),
      (item: any) => item.value === booking.config.session.duration,
    );

    // If the session duration is maped out (which normally it shoud be) then it gets the number of credits for it
    if (creditsPerParticipantSessionDuration) return creditsPerParticipantSessionDuration.creditsPerParticipant;

    // If the session duration has been changed mannually then it should charge accordingly
    if (booking.config.session.duration <= 60) return 100;
    if (booking.config.session.duration > 60 && booking.config.session.duration <= 120) return 150;
    return 200;
  },
  getCreditsForParticipantCategory(booking: any, participantCategory = 1) {
    const participant_category = _.get(booking, 'config.participant_category') || participantCategory;
    switch (participant_category) {
      case 1:
        return 1;
      case 2: {
        // 5-10 mins survey - 2.5x
        // 15-30 min survey - 2x
        if (bookingUtils.isSurvey(booking)) {
          if (_.get(booking, 'config.session.duration') === 5 || _.get(booking, 'config.session.duration') === 10) {
            return 2.5;
          }

          return 2;
        }
        return 1.5;
      }
      default:
        return 1;
    }
  },
  getLongitudinalStudyMultipliers(type = 1) {
    switch (type) {
      // Minutes
      case 1:
        return 0.8;
      // Days
      case 2:
        return 10;
      default:
        return 1;
    }
  },
  getLongitudinalStudyParticipantWorkload(booking: any) {
    // If booking is not longitudinal return 0
    if (!bookingUtils.isLongitudinal(booking)) return 0;

    // If booking don't have extra times return 0
    if (!_.get(booking, 'config.longitudinal_study.participant_workload')) return 0;

    // Sum total period
    const workloadTime = _.get(booking, 'config.longitudinal_study.participant_workload.time', 0);
    const workloadMeasure = _.get(booking, 'config.longitudinal_study.participant_workload.measure');
    const workloadFrequency = _.get(booking, 'config.longitudinal_study.participant_workload.frequency');

    const periodTime = _.get(booking, 'config.longitudinal_study.period.time', 0);
    const periodFrequency = _.get(booking, 'config.longitudinal_study.period.frequency');

    // Convert time to minutes
    const workloadTimeMinutes = workloadMeasure === 2 ? workloadTime * 60 : workloadTime;

    // Return total workload time
    switch (workloadFrequency) {
      // Workload in Days
      case 1:
        const daysPeriod = bookingUtils.getLongitudinalStudyPeriod(booking);
        return workloadTimeMinutes * daysPeriod;

      // Workload in weeks
      case 2:
        // Convert to respective period
        switch (periodFrequency) {
          // Period in days
          case 1:
            // If period days is lower than a week, consider 1 week
            if (periodTime <= 7) return workloadTimeMinutes;

            // If period days is larger than a week, convert to week
            return workloadTimeMinutes * (periodTime / 7);

          // Period in weeks, just multiply
          case 2:
            return workloadTimeMinutes * periodTime;

          // Period in months, convert period in weeks
          case 3:
            return workloadTimeMinutes * (periodTime * 4);

          default:
            return workloadTimeMinutes;
        }

      // Workload in months
      case 3:
        switch (periodFrequency) {
          // Period in days
          case 1:
            // If period days is lower than a month, consider 1 month
            if (periodTime <= 30) return workloadTimeMinutes;

            // If period days is larger than a month, convert to month
            return workloadTimeMinutes * (periodTime / 30);

          // Period in weeks, just multiply
          case 2:
            // If period weeks is lower than a month, consider 1 month
            if (periodTime <= 4) return workloadTimeMinutes;

            // If period weeks is larger than a month, convert to month
            return workloadTimeMinutes * (periodTime / 4);

          // Period in months, just multiply
          case 3:
            return workloadTimeMinutes * periodTime;

          default:
            return workloadTimeMinutes;
        }

      default:
        return 0;
    }
  },
  getLongitudinalStudyPeriod(booking: any) {
    // If booking is not longitudinal return 0
    if (!bookingUtils.isLongitudinal(booking)) return 0;

    // If booking don't have extra times return 0
    if (!_.get(booking, 'config.longitudinal_study.period')) return 0;

    // Sum total period
    const periodTime = _.get(booking, 'config.longitudinal_study.period.time', 0);
    const periodFrequency = _.get(booking, 'config.longitudinal_study.period.frequency');

    // Convert period to days
    switch (periodFrequency) {
      case 1:
        return periodTime; // Day
      case 2:
        return periodTime * 7; // Week
      case 3:
        return periodTime * 31; // Month
      default:
        return 0;
    }
  },
  getLongitudinalStudyExtraTime(booking: any) {
    // If booking is not longitudinal return 0
    if (!bookingUtils.isLongitudinal(booking)) return 0;

    // If booking don't have extra times return 0
    if (_.size(_.get(booking, 'config.longitudinal_study.extra')) === 0) return 0;

    // Sum extra times
    let totalExtraMinutes = 0;
    _.map(_.get(booking, 'config.longitudinal_study.extra'), (item: any) => {
      const extraTime = _.get(item, 'time', 0);
      // If time is in hour, convert to minutes
      totalExtraMinutes += _.get(item, 'measure') === 2 ? extraTime * 60 : extraTime;
    });

    return totalExtraMinutes;
  },
  getCreditsPerParticipant(booking: any) {
    // If booking is completed return the credits per participant saved on booking
    if (_.get(booking, 'status') === 5 && _.has(booking, 'config.credits_per_participant')) {
      return _.get(booking, 'config.credits_per_participant');
    }
    // Function to calculate how many credits are needed to get a participant
    const creditsForParticipantType = bookingUtils.getCreditsForParticipantCategory(booking);
    const recruitmentDiscount = _.get(booking, 'config.recruitment.byo') || false ? 0.7 : 1;
    let additionalCredits = 0;

    // Calculate credits per participants for longitudinal studies
    if (bookingUtils.isLongitudinal(booking)) {
      // Get multipliers
      const minutesMultiplier = bookingUtils.getLongitudinalStudyMultipliers(1);
      const daysMultiplier = bookingUtils.getLongitudinalStudyMultipliers(2);

      // Check this base credits
      const baseCredits = 30;
      // Get total minutes workload
      const totalMinutesWorkload = bookingUtils.getLongitudinalStudyParticipantWorkload(booking);
      // Get total extra minutes
      const totalMinutesExtraTime = bookingUtils.getLongitudinalStudyExtraTime(booking);
      // Calc total credits per minute (workload time and extra time)
      const totalCreditsPerMinute = (totalMinutesWorkload + totalMinutesExtraTime) * minutesMultiplier;
      // Get total days in period
      const totalDaysPeriod = bookingUtils.getLongitudinalStudyPeriod(booking);
      // Calc total credits to period
      const totalCreditsPerDay = totalDaysPeriod * daysMultiplier;
      // Sum total credits per participant (base credits, workload, extra time and períod)
      const totalCreditsPerParticipant = baseCredits + totalCreditsPerMinute + totalCreditsPerDay;

      // Return credits per participant adjusted per participant category (general/professional) and BYO
      return Math.ceil(totalCreditsPerParticipant * creditsForParticipantType * recruitmentDiscount);
    }

    // This logic will grow as the business change the logic
    // Logic
    //   * For sessions longer than 1 hour, it should charge 150 credits
    //   * For business owners, it should charge 200 credits
    //   * If it's a BYOD booking, it should not charge the recruitment fee
    const participantsPerCredit = bookingUtils.getNumberOfParticipantsPerCredit(booking);
    let creditsForSessionDuration = bookingUtils.getCreditsForSessionDuration(booking);
    // Each booking type could use a different number of credits
    creditsForSessionDuration /= participantsPerCredit;

    // Charge +5 credits per participant for custom agreements
    if (
      _.get(booking, 'config.participant_agreement.type') === BOOKING_CONFIG_PARTICIPANT_AGREEMENT_TYPE.CUSTOM_AGREEMENT
    ) {
      additionalCredits += 5;
    }

    // Adjust credits per participant for Askable Live sessions
    if (bookingUtils.isRemote(booking) && bookingUtils.isAskableLive(booking)) {
      additionalCredits += 5;
    }

    return Math.ceil(creditsForSessionDuration * creditsForParticipantType * recruitmentDiscount + additionalCredits);
  },
  getBookingLinkURL(booking: Pick<BookingType, 'config' | '_id' | 'status' | 'type'>) {
    switch (booking.status) {
      case 0:
        return { url: `/booking-setup/${booking._id}/project-setup/project-title` };
      case 1:
        return {
          url: `/bookings/${booking._id}/participants/all`,
          state: { booking },
        };
      case 2:
        return { url: `/booking-setup/${booking._id}/project-setup/project-title`, state: { bookingID: booking._id } };
      case 3:
        return {
          url: `/bookings/${booking._id}/participants/all`,
          state: { booking },
        };
      case 4:
        return { url: '/', state: { booking } };
      case 5:
        return { url: `/bookings/${booking._id}/participants/all`, state: { booking } };
      case 6:
        return { url: `/bookings/${booking._id}/participants/all`, state: { booking } };
      default:
        return { url: `/booking-setup/${booking._id}/project-setup/project-title`, state: { bookingID: booking._id } };
    }
  },
  getBookingDateSummary(booking: any) {
    if (_.size(booking.session) === 0) return '';
    if (_.size(booking.session) === 1) return utils.formatTimestampToDate(booking.session[0].start, 'DD MMM YYYY');
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const startDate = utils.formatTimestampToDate(_.minBy(booking.session, 'start').start, 'DD MMM YYYY');
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const endDate = utils.formatTimestampToDate(_.maxBy(booking.session, 'start').start, 'DD MMM YYYY');
    if (startDate === endDate) return startDate;
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const shortStartDate = utils.formatTimestampToDate(_.minBy(booking.session, 'start').start, 'DD MMM');
    return `${shortStartDate} - ${endDate}`;
  },
  isBookingForProfessionals(booking?: Pick<Booking, 'config'>) {
    // 1 - General, 2 - Professionals
    return booking?.config?.participant_category === 2;
  },
  isBookingAutomated(booking: any) {
    // Returns true if the review_submissions flag is false
    if (_.has(booking, 'config.options.review_submission')) return booking.config.options.review_submission === false;
    return false;
  },
  isInReview(booking?: Pick<BookingType, 'status'> | null) {
    return booking?.status === BOOKING_STATUS.IN_REVIEW || booking?.status === BOOKING_STATUS.PENDING_PAYMENT;
  },
  isInDraft(booking?: Pick<BookingType, 'status'> | null) {
    return booking?.status === BOOKING_STATUS.DRAFT;
  },
  isActive(booking?: Pick<BookingType, 'status'> | null) {
    return booking?.status === BOOKING_STATUS.ACTIVE;
  },
  isCompleted(booking?: Pick<BookingType, 'status'> | null) {
    return booking?.status === BOOKING_STATUS.COMPLETED;
  },
  isOnlineTask(booking?: Pick<BookingType, 'type'> | null) {
    return booking?.type === BOOKING_TYPE.ONLINE;
  },
  isInPerson(booking?: Pick<BookingType, 'type'> | null) {
    return booking?.type === BOOKING_TYPE.IN_PERSON;
  },
  isRemote(booking?: Pick<BookingType, 'type'> | null) {
    return booking?.type === BOOKING_TYPE.REMOTE;
  },
  isLongitudinal(booking?: Pick<BookingType, 'type'> | null) {
    return booking?.type === BOOKING_TYPE.LONGITUDINAL_STUDY;
  },
  isFocusGroup(booking?: Pick<BookingType, 'config'> | null) {
    return booking?.config?.session?.type === 2;
  },
  isInContextResearch(booking?: Pick<BookingType, 'config'> | null) {
    // Checks whether or not the location_type field has been set
    return booking?.config?.in_context?.location_type || false;
  },
  isBYO(booking?: Pick<BookingType, 'config'> | null) {
    if (!booking) return false;
    return booking.config?.recruitment?.byo || false;
  },
  getCustomSortLogic(bookings: any) {
    const bookingsArray: any = [];
    // 1 -> In review bookings
    const inReviewBookings = bookings.filter((booking: any) => booking.status === 3);
    _.each(inReviewBookings, (booking: any) => bookingsArray.push(booking));

    // 2 -> Active bookings (Excluding demo bookings)
    const activeBookings = bookings.filter((booking: any) => booking.status === 1 && !_.get(booking, 'config.demo'));
    _.each(activeBookings, (booking: any) => bookingsArray.push(booking));

    // 3 -> Draft bookings
    const draftBookings = bookings.filter((booking: any) => booking.status === 0);
    _.each(draftBookings, (booking: any) => bookingsArray.push(booking));

    // 4 -> Completed bookings
    const completedBookings = bookings.filter((booking: any) => booking.status === 5);
    _.each(completedBookings, (booking: any) => bookingsArray.push(booking));

    // 5 -> Archived bookings
    const archivedBookings = bookings.filter((booking: any) => booking.status === 7);
    _.each(archivedBookings, (booking: any) => bookingsArray.push(booking));

    // 6 -> Active bookings (Just demo bookings)
    const demoBookings = bookings.filter((booking: any) => booking.status === 1 && _.get(booking, 'config.demo'));
    _.each(demoBookings, (booking: any) => bookingsArray.push(booking));

    return bookingsArray;
  },
  saveFiltersIntoLocalStorage(booking_id: any, filters: any) {
    // Method to save a specific filter into local storage
    // This is used on the persistent filters on the cherry picking list
    // It also adds / updates a timestamp to later be able to clear the local storage
    let savedFilters = _.clone(filters);
    savedFilters = JSON.stringify(savedFilters);
    localStorage.save(`bookingFilters-${booking_id}`, savedFilters);
  },
  saveSortingIntoLocalStorage(booking_id: any, sorting: any) {
    // Method to save a specific filter into local storage
    // This is used on the persistent filters on the cherry picking list
    // It also adds / updates a timestamp to later be able to clear the local storage
    let savedSorting = _.clone(sorting);
    savedSorting = JSON.stringify(savedSorting);
    localStorage.save(`bookingSorting-${booking_id}`, savedSorting);
  },
  saveHiddenParticipantsIntoLocalStorage(booking_id: any, hiddenParticipants: any) {
    // Method to save participants to hide into local storage
    // This is used on the persistent filters on the cherry picking list
    // It also adds / updates a timestamp to later be able to clear the local storage
    const savedHiddenParticipants = _.clone(hiddenParticipants);
    localStorage.save(`bookingHiddenParticipants-${booking_id}`, savedHiddenParticipants);
  },
  saveShortlistedParticipantsIntoLocalStorage(booking_id: any, shortlistedParticipants: any) {
    // Method to save participants to hide into local storage
    // This is used on the persistent filters on the cherry picking list
    // It also adds / updates a timestamp to later be able to clear the local storage
    const savedShortlistedParticipants = _.clone(shortlistedParticipants);
    localStorage.save(`bookingShortlistedParticipants-${booking_id}`, savedShortlistedParticipants);
  },
  cleanUpFiltersIntoLocalStorage() {
    // Function to clean up the persistent filters from the local storage
    // Persistent filters are saved when a client puts a filter against a booking
    const savedStorage = localStorage.getAll();

    // Creates a new object filtering just the filters from the localStorage
    const savedFilters = _.chain(savedStorage)
      .keys()
      .filter((key: any) => key.includes('filters-'))
      .reduce((obj: any, key: any) => {
        return {
          ...obj,
          [key]: savedStorage[key],
        };
      }, {})
      .value();

    // Checks whether the user has saved some filter
    if (_.size(savedFilters) > 0) {
      // Loop through the filters to check which one is already expired
      _.each(savedFilters, (filter: any, key: any) => {
        const parsedFilter = utils.parseJSON(filter);
        // Gets the creation date
        const creationDate = _.get(
          _.find(parsedFilter, (item: any) => !item.id),
          'created',
        );
        if (creationDate && Math.abs(utils.getDifferenceFromNow(creationDate, 'd')) > 14) {
          // Remove the persistent filter from the localStorage
          localStorage.remove(key);
        }
      });
    }
  },
  formatBookingLocation(location: any) {
    let formattedLocation = '';

    if (_.get(location, 'in_context_type')) {
      formattedLocation = location.in_context_type;
    }

    if (_.get(location, 'level')) {
      formattedLocation = `${location.level}, `;
    }

    return `${formattedLocation}${_.get(location, 'street1')}, ${_.get(location, 'city')}`;
  },
  getSessionStatus(currentSessionStatus: any, session: any, participantApplications: any, timezone: any) {
    if (!currentSessionStatus) {
      return {
        label: '',
        labelAction: '',
        class: 'available',
      };
    }
    // Search for a happening session
    if (
      currentSessionStatus.status === 1 &&
      currentSessionStatus.cancel === 0 &&
      momentTimezone(session.start).tz(timezone).isBefore() &&
      momentTimezone(session.end).tz(timezone).isAfter()
    ) {
      return {
        label: 'Confirmed',
        labelAction: 'Happening now',
        class: 'confirmed',
        confirmation_field: 'session_invitation_accepted',
      };
    }

    // Search for a confirmed session that hasn't been started yet
    if (
      currentSessionStatus.status === 1 &&
      currentSessionStatus.cancel === 0 &&
      momentTimezone(session.start).tz(timezone).isAfter()
    ) {
      return {
        label: 'Confirmed',
        labelAction: 'Confirmed',
        class: 'confirmed',
        confirmation_field: 'session_invitation_accepted',
      };
    }

    // Search for a confirmed session that has finished yet
    if (
      currentSessionStatus.status === 1 &&
      (currentSessionStatus.cancel === 0 ||
        (currentSessionStatus.cancel === 7 && _.get(currentSessionStatus, 'rating.overall'))) &&
      momentTimezone(session.end).tz(timezone).isBefore()
    ) {
      return {
        label: 'Completed',
        labelAction: 'Completed',
        class: 'completed',
        confirmation_field: 'session_completed',
      };
    }

    // Search for a waitlisted session
    if (currentSessionStatus.status === 2 && currentSessionStatus.cancel === 0) {
      return {
        label: 'Waitlisted',
        labelAction: 'Waitlisted',
        class: 'waitlisted',
        confirmation_field: 'session_invitation_accepted',
      };
    }

    // Search for an invited session
    if (currentSessionStatus.status === 4 && currentSessionStatus.cancel === 0) {
      const invitedParticipantsForSession = _.filter(
        participantApplications,
        (ppt: any) => ppt.status === 4 && ppt.cancel === 0,
      );
      return {
        label: `Invite${_.size(invitedParticipantsForSession) > 1 ? 's' : ''} sent`,
        labelAction: `Invite${_.size(invitedParticipantsForSession) > 1 ? 's' : ''} sent`,
        class: 'invited',
        confirmation_field: 'session_invitation_notification',
      };
    }

    // Search for an invited session that has been declined
    if (currentSessionStatus.status === 4 && currentSessionStatus.cancel === 3) {
      const invitedParticipantsForSession = _.size(
        _.filter(participantApplications, (ppt: any) => ppt.status === 4 && ppt.cancel === 0),
      );
      const cancelledInvitationForSession = _.size(
        _.filter(participantApplications, (ppt: any) => ppt.status === 4 && ppt.cancel === 3),
      );
      if (invitedParticipantsForSession > cancelledInvitationForSession) {
        return {
          label: `Invite${invitedParticipantsForSession > 1 ? 's' : ''} sent`,
          labelAction: `Invite${invitedParticipantsForSession > 1 ? 's' : ''} sent`,
          class: 'invited',
          confirmation_field: 'session_invitation_notification',
        };
      }
      return {
        label: `Invite${cancelledInvitationForSession > 1 ? 's' : ''} declined`,
        labelAction: `Invite${cancelledInvitationForSession > 1 ? 's' : ''} declined`,
        class: 'invite-declined',
        confirmation_field: 'session_invitation_notification',
      };
    }

    // Search for an invited session that has been revoked
    if (currentSessionStatus.status === 4 && currentSessionStatus.cancel === 2) {
      return {
        label: 'Invite revoked',
        labelAction: 'Invite revoked',
        class: 'invite-revoked',
        confirmation_field: 'session_invitation_notification',
      };
    }

    // Search for no show session
    if (currentSessionStatus.status === 1 && currentSessionStatus.cancel === 4) {
      return {
        label: 'No Show',
        labelAction: 'No Show',
        class: 'no-show',
      };
    }

    // Search for participant issues session
    if (currentSessionStatus.status === 1 && currentSessionStatus.cancel === 7) {
      return {
        label: 'Issue reported',
        labelAction: 'Issue reported',
        class: 'cancelled',
      };
    }

    // Search for an available session
    if (currentSessionStatus.status === 3 && currentSessionStatus.cancel === 0) {
      return {
        label: '',
        labelAction: '',
        class: 'available',
      };
    }

    // Search for cancelled sessions
    if (currentSessionStatus.cancel > 0) {
      return {
        label: 'Cancelled',
        labelAction: 'Cancelled',
        class: 'cancelled',
        confirmation_field: 'session_confirmed_cancellation',
      };
    }
  },
  isStepValidated(booking: any, step: any) {
    // Check whether or not the step have been validated already
    const isValidated = _.chain(booking)
      .get('config.steps')
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'filter' does not exist on type 'Function... Remove this comment to see the full error message
      .filter((steps: any) => steps.step === step)
      .size()
      .value();
    return isValidated > 0;
  },
  onlineTaskHasMultipleVariations(booking: any) {
    // Check whether the online task has more than 1 variation
    if (!bookingUtils.isOnlineTask(booking)) return false;

    return _.size(_.get(booking, 'config.online_task.links')) > 1;
  },
  isAskableLive(booking: any): boolean {
    return !!booking?.config?.remote?.askable_live;
  },
  isAskablePlus(booking: Object) {
    return _.get(booking, 'config.project.project_type') === 1;
  },
  isSurvey(booking: any) {
    return _.get(booking, 'config.online_task.type') === 2;
  },
  isAiModeration(booking: any) {
    return _.get(booking, 'config.online_task.type') === 3;
  },
  getBookingTypeSettings(booking: any) {
    // Method to return the booking type based on the method bookingTypes
    // It should test whether or not the booking has a subtype
    const bookingTypes = bookingUtils.bookingTypes(booking);
    if (bookingUtils.isOnlineTask(booking)) {
      if (bookingUtils.isSurvey(booking)) {
        return _.find(bookingTypes, (item: any) => {
          return (
            item.bookingType === _.get(booking, 'type') &&
            item.bookingSubType === _.get(booking, 'config.online_task.type')
          );
        });
      }
      if (bookingUtils.isAiModeration(booking)) {
        return _.find(bookingTypes, (item: any) => {
          return (
            item.bookingType === _.get(booking, 'type') &&
            item.bookingSubType === _.get(booking, 'config.online_task.type')
          );
        });
      }
    }
    return _.find(bookingTypes, (item: any) => item.bookingType === _.get(booking, 'type'));
  },
  // Finds out how many participants a credit is worth for a specific booking
  getNumberOfParticipantsPerCredit(booking: any) {
    if (bookingUtils.isOnlineTask(booking)) return 5;
    return 1;
  },
  getBookingParticipantsGender(booking: any) {
    const maleGender = bookingUtils.getCriteriaValue(
      { parent: 'meta_identity_gender', field: 'user.meta.identity.gender', value: 'male' },
      booking,
    );
    const femaleGender = bookingUtils.getCriteriaValue(
      { parent: 'meta_identity_gender', field: 'user.meta.identity.gender', value: 'female' },
      booking,
    );
    const nonBinaryGender = bookingUtils.getCriteriaValue(
      { parent: 'meta_identity_gender', field: 'user.meta.identity.gender', value: 'non-binary' },
      booking,
    );
    if (maleGender && femaleGender) return 1;
    if (maleGender) return 2;
    if (femaleGender) return 3;
    if (nonBinaryGender) return 0;
    return 1;
  },
  getBriefSummaryExample(booking: any) {
    const bookingType = _.get(booking, 'config.session.type') === 1 ? '1 on 1 chat' : 'focus group';

    // There are two different templates on the booking. It changes depending on the booking type
    // `We’re looking for anyone in ${localStorage.get('cityByIp')} who is AGE.RANGE(between 18-35 years old || over 18).to participate in a paid research study. The format will be a casual BOOKING.TYPE (1 on 1 chat || focus group || over the phone chat) where we’ll ask you to do some basic tasks or answer some questions. There’s no right or wrong answers, we’re just looking for feedback!`
    let ageCriteria = '';
    if (_.size(_.get(booking, 'config.criteria.meta_identity_birthday_year') || []) > 0) {
      const maxAgeCriteria = bookingUtils.getCriteriaValue(
        { parent: 'meta_identity_birthday_year', field: 'user.meta.identity.birthday.timestamp', operator: 'gt' },
        booking,
      );
      const minAgeCriteria = bookingUtils.getCriteriaValue(
        { parent: 'meta_identity_birthday_year', field: 'user.meta.identity.birthday.timestamp', operator: 'lt' },
        booking,
      );
      ageCriteria = `You must be over ${utils.getCurrentYear() - utils.getYearFromTimestamp(minAgeCriteria)} years old.`;
      // If the maxAgeCriteria is lower than 1900 it means the client hasn't set the max age
      if (utils.getYearFromTimestamp(maxAgeCriteria) > 1900) {
        ageCriteria = `You must be between ${utils.getCurrentYear() - utils.getYearFromTimestamp(minAgeCriteria)}-${
          utils.getCurrentYear() - utils.getYearFromTimestamp(maxAgeCriteria)
        } years old.`;
      }
    }

    switch (booking.type) {
      case 1:
        return `We’re looking for anyone to come in for a user interview and give us some product feedback. ${ageCriteria} It will be a casual ${bookingType} where we’ll ask you to do some basic tasks or answer some questions. There’s no right or wrong answers, we’re just looking for feedback!`;
      case 2:
        return `We’re looking for anyone to give us some product feedback. ${ageCriteria} It will be a casual ${bookingType} where we’ll ask you to do some basic tasks or answer some questions. There’s no right or wrong answers, we’re just looking for feedback!`;
      case 3: {
        if (bookingUtils.isAiModeration(booking)) {
          return "We're looking for people to join our discovery video interview conducted by an AI researcher. There are no right or wrong answers, we’re just looking for your feedback.";
        }
        return `We're looking for people to give us some product feedback through an online ${
          bookingUtils.isSurvey(booking) ? 'survey' : 'task'
        }. ${ageCriteria} It will be a task online and you should follow the prompts clearly. There are no right or wrong answers, we're just looking for feedback!`;
      }
      case 4:
        const workloadTime = _.get(booking, 'config.longitudinal_study.participant_workload.time');
        const workloadMeasure = _.get(booking, 'config.longitudinal_study.participant_workload.measure');
        const workloadFrequency = _.get(booking, 'config.longitudinal_study.participant_workload.frequency');
        const workloadMeasureLabel = bookingUtils.getMeasureLabel(workloadMeasure, workloadTime > 1);
        const workloadFrequencyLabel = bookingUtils.getFrequencyLabel(workloadFrequency);
        const periodTime = _.get(booking, 'config.longitudinal_study.period.time', '');
        const periodFrequency = _.get(booking, 'config.longitudinal_study.period.frequency');
        const periodFrequencyLabel = bookingUtils.getFrequencyLabel(periodFrequency, periodTime > 1);
        return `We're looking for people to participate in our new diary study. ${ageCriteria} The study will be conducted over ${periodTime}-${periodFrequencyLabel}. It will be a casual research where you will be required to spend ${workloadTime} ${workloadMeasureLabel} per ${workloadFrequencyLabel}, completing some basic tasks or answering some questions. There are no right or wrong answers, we're just looking for feedback!`;
      default:
        return '';
    }
  },
  getAgesTitle(booking: any) {
    const currentYear = utils.getCurrentYear();
    const bookingMaxAge = bookingUtils.getCriteriaValue(
      { parent: 'meta_identity_birthday_year', field: 'user.meta.identity.birthday.timestamp', operator: 'gt' },
      booking,
    );
    const bookingMinAge = bookingUtils.getCriteriaValue(
      { parent: 'meta_identity_birthday_year', field: 'user.meta.identity.birthday.timestamp', operator: 'lt' },
      booking,
    );
    const minAge = bookingMinAge ? currentYear - utils.getYearFromTimestamp(bookingMinAge) : '18';
    const maxAge = bookingMaxAge ? currentYear - utils.getYearFromTimestamp(bookingMaxAge) : '100+';
    return <p className="formField">{`Ages ${minAge} - ${Number(maxAge) > 100 ? '100+' : maxAge}`}</p>;
  },
  getGenderTitle(booking: any) {
    const maleGender = bookingUtils.getCriteriaValue(
      { parent: 'meta_identity_gender', field: 'user.meta.identity.gender', value: 'male' },
      booking,
    );
    const femaleGender = bookingUtils.getCriteriaValue(
      { parent: 'meta_identity_gender', field: 'user.meta.identity.gender', value: 'female' },
      booking,
    );
    const nonBinaryGender = bookingUtils.getCriteriaValue(
      { parent: 'meta_identity_gender', field: 'user.meta.identity.gender', value: 'non-binary' },
      booking,
    );

    if (maleGender && femaleGender) return <p className="formField">All genders</p>;
    if (maleGender) {
      return (
        <p className="formField">
          Males <strong>only</strong>
        </p>
      );
    }
    if (femaleGender) {
      return (
        <p className="formField">
          Females <strong>only</strong>
        </p>
      );
    }
    if (nonBinaryGender) {
      return (
        <p className="formField">
          Non-binary <strong>only</strong>
        </p>
      );
    }
    return <p className="formField">All genders</p>;
  },
  getCurrentGenderValue(booking: any) {
    const maleGender = bookingUtils.getCriteriaValue(
      { parent: 'meta_identity_gender', field: 'user.meta.identity.gender', value: 'male' },
      booking,
    );
    const femaleGender = bookingUtils.getCriteriaValue(
      { parent: 'meta_identity_gender', field: 'user.meta.identity.gender', value: 'female' },
      booking,
    );
    const nonBinaryGender = bookingUtils.getCriteriaValue(
      { parent: 'meta_identity_gender', field: 'user.meta.identity.gender', value: 'non-binary' },
      booking,
    );
    if (maleGender && femaleGender) return 1;
    if (maleGender) return 2;
    if (femaleGender) return 3;
    if (nonBinaryGender) return 0;
    return 1;
  },
  measureTimes() {
    return [
      {
        label: '5 mins',
        time: 5,
        measure: 1,
        measureLabel: 'minutes',
      },
      {
        label: '10 mins',
        time: 10,
        measure: 1,
        measureLabel: 'minutes',
      },
      {
        label: '15 mins',
        time: 15,
        measure: 1,
        measureLabel: 'minutes',
      },
      {
        label: '20 mins',
        time: 20,
        measure: 1,
        measureLabel: 'minutes',
      },
      {
        label: '25 mins',
        time: 25,
        measure: 1,
        measureLabel: 'minutes',
      },
      {
        label: '30 mins',
        time: 30,
        measure: 1,
        measureLabel: 'minutes',
      },
      {
        label: '45 mins',
        time: 45,
        measure: 1,
        measureLabel: 'minutes',
      },
      {
        label: '1 hour',
        time: 1,
        measure: 2,
        measureLabel: 'hour',
      },
      {
        label: '2 hours',
        time: 2,
        measure: 2,
        measureLabel: 'hours',
      },
      {
        label: '3 hours',
        time: 3,
        measure: 2,
        measureLabel: 'hours',
      },
      {
        label: '4 hours',
        time: 4,
        measure: 2,
        measureLabel: 'hours',
      },
      {
        label: '5 hours',
        time: 5,
        measure: 2,
        measureLabel: 'hours',
      },
      {
        label: '6 hours',
        time: 6,
        measure: 2,
        measureLabel: 'hours',
      },
      {
        label: '7 hours',
        time: 7,
        measure: 2,
        measureLabel: 'hours',
      },
      {
        label: '8 hours',
        time: 8,
        measure: 2,
        measureLabel: 'hours',
      },
      {
        label: '9 hours',
        time: 9,
        measure: 2,
        measureLabel: 'hours',
      },
      {
        label: '10 hours',
        time: 10,
        measure: 2,
        measureLabel: 'hours',
      },
    ];
  },
  measureTypes(plural = false) {
    return [
      {
        label: `${plural ? 'mins' : 'min'}`,
        shortLabel: `${plural ? 'mins' : 'min'}`,
        fullLabel: `${plural ? 'Minutes' : 'Minute'}`,
        value: 1,
      },
      {
        label: `${plural ? 'hours' : 'hour'}`,
        shortLabel: `${plural ? 'hrs' : 'hr'}`,
        fullLabel: `${plural ? 'Hours' : 'Hour'}`,
        value: 2,
      },
    ];
  },
  frequencyTypes(plural = false) {
    return [
      {
        label: `${plural ? 'days' : 'day'}`,
        fullLabel: `${plural ? 'Days' : 'Day'}`,
        value: 1,
      },
      {
        label: `${plural ? 'weeks' : 'week'}`,
        fullLabel: `${plural ? 'Weeks' : 'Week'}`,
        value: 2,
      },
      {
        label: `${plural ? 'months' : 'month'}`,
        fullLabel: `${plural ? 'Months' : 'Month'}`,
        value: 3,
      },
    ];
  },
  getMeasureLabel(value: any, plural = false, labelType = null) {
    const measure = _.find(bookingUtils.measureTypes(), (item: any) => item.value === value);
    if (labelType === 'shortLabel') return `${_.get(measure, 'shortLabel', '')}${plural ? 's' : ''}`;
    if (labelType === 'fullLabel') return `${_.get(measure, 'fullLabel', '')}${plural ? 's' : ''}`;
    return `${_.get(measure, 'label', '')}${plural ? 's' : ''}`;
  },
  getFrequencyLabel(value: any, plural = false, fullLabel = false) {
    const frequency = _.find(bookingUtils.frequencyTypes(), (item: any) => item.value === value);
    if (fullLabel) return `${_.get(frequency, 'fullLabel', '')}${plural ? 's' : ''}`;
    return `${_.get(frequency, 'label', '')}${plural ? 's' : ''}`;
  },
  async getLocationPolygons(locationName: string) {
    if (!locationName) return null;
    const locationPolygon = await fetch(`/polygons/${locationName}.json`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((polygonData) => {
        return bookingUtils.formatGeojson([polygonData]);
      })
      .catch(() => {
        return null;
      });

    return locationPolygon;
  },
  formatGeojson(coordinates: any) {
    if (!coordinates) return null;
    const coordinatesData = coordinates;

    const geoJson = _.map(coordinatesData, (locationCoordinate: any) => {
      return _.map(locationCoordinate, (coordinate: any) => {
        return _.map(coordinate, (coord: any) => {
          return _.map(coord, (item: any) => {
            if (item) return { lat: item[1], lng: item[0] };
          });
        });
      });
    });
    // review this code, some polygons dont work
    const polygonGeoJson = _.map(geoJson, (item: any) => {
      return _.map(item, (x: any) => x[0]);
    });
    return polygonGeoJson;
  },
  manualClosingReason() {
    return [
      {
        label: 'Change of mind',
        value: 1,
      },
      {
        label: 'Sufficient responses',
        value: 2,
      },
      {
        label: 'Study due',
        value: 3,
      },
      {
        label: 'Scheduling issues',
        value: 4,
      },
      {
        label: 'Cleaning up study screen',
        value: 5,
      },
      {
        label: 'Under-recruited',
        value: 6,
      },
      {
        label: 'Participant cancellation',
        value: 7,
      },
      {
        label: 'Other',
        value: 8,
      },
    ];
  },
  getRefundedParticipantsForIssuesOrNoShows(session: any) {
    return _.sumBy(session || [], ({ participants }: any) => {
      // prettier-ignore
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(p: any) => boolean' is not assi... Remove this comment to see the full error message
      return _.sumBy(participants, (p: any) => ((p.cancel === 4 && p.no_show_request === 1) || (p.cancel === 7 && p.issue_request === 1)) && p.status === 1);
    });
  },
  calendarResearchersColors() {
    return [
      '#00D1FF',
      '#7868E6',
      '#FFA41B',
      '#FF7777',
      '#30E7A1',
      '#616A74',
      '#B91646',
      '#FF5733',
      '#1DC4A9',
      '#3E64FF',
      '#610094',
    ];
  },
  getBookingResearchers(sessionTimes: any, viewerId: string, viewerName: string) {
    const sessionResearchers = [
      {
        _id: viewerId,
        name: utils.getNameInitials(viewerName),
        color: '#00D1FF',
      },
    ];

    _.map(sessionTimes, (session) => {
      if (!_.get(session, '_researcher_user_id', '')) return;
      const userAddedToList = _.find(
        sessionResearchers,
        (researcher) => researcher._id === _.get(session, '_researcher_user_id', '').toString(),
      );
      if (!userAddedToList) {
        sessionResearchers.push({
          _id: session._researcher_user_id.toString(),
          name: utils.getNameInitials(_.get(session, 'ResearcherUser.name', '')),
          color: bookingUtils.calendarResearchersColors()[_.size(sessionResearchers)],
        });
      }
    });

    return sessionResearchers as any;
  },
  canDuplicate(booking: Maybe<{ config?: Maybe<Pick<BookingConfig, 'demo'>> }>, isResearcher = false) {
    return !booking?.config?.demo && !isResearcher;
  },
  canArchive(
    booking: Maybe<Pick<Booking, 'status'> & { config?: Maybe<Pick<BookingConfig, 'demo'>> }>,
    isResearcher = false,
  ) {
    return !isResearcher && (booking?.status === 0 || booking?.status === 5 || booking?.config?.demo);
  },
  canRateExperience(
    booking: Maybe<
      { rating?: Maybe<Pick<BookingRating, 'overall' | 'product_quality' | 'participant_quality'>> } & Maybe<
        Pick<Booking, 'status'>
      >
    >,
    isNewPssBooking: boolean,
  ) {
    const isCompleted = this.isCompleted(booking);
    if (!isCompleted) return false;
    return isNewPssBooking
      ? !booking?.rating?.overall || !booking.rating.product_quality || !booking.rating.participant_quality
      : !booking?.rating?.overall;
  },
  // used when needing display locations to the user, eg: "Your booking is in Sydney, NSW, Australia and Texas, USA"
  formattedLocations(locations: LocationCriteria) {
    return LOCATIONS_KEYS.flatMap((key) =>
      locations[key]?.map((item: Maybe<Location>) => item?.formatted_address),
    ).filter(Boolean) as string[];
  },
  allLocations(locations: LocationCriteria) {
    return LOCATIONS_KEYS.flatMap((key) => locations[key]).filter(Boolean) as Location[];
  },
  allCountries(locations?: LocationCriteria | null) {
    if (!locations) return [];
    return [
      ...new Set(LOCATIONS_KEYS.flatMap((key) => locations[key]?.map((item: Maybe<Location>) => item?.country))),
    ].filter(Boolean) as CountryCode[];
  },
};

export { bookingUtils };
