import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { useStudyContext } from '../StudiesContainer';
import { getPageTitle } from '../utils/getPageTitle';

export const Recruit = () => {
  const { t } = useTranslation();
  const { study } = useStudyContext();

  return (
    <>
      <Helmet>
        <title>{getPageTitle(t('sections.studies.recruit'), study.name)}</title>
      </Helmet>

      <div className="recruit-container">
        <nav className="recruit-nav bg-background">
          <ul className="flex flex-col gap-2 overflow-auto bg-background p-4">
            <li>Nav column</li>
          </ul>
        </nav>

        <main className="main overflow-auto p-4">
          <div className="h-full rounded-xl bg-background p-4">{t('sections.studies.recruit')}</div>
        </main>
      </div>
    </>
  );
};
