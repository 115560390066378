import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AnchorButton } from '@askable/ui/components/ui/button';
import { ExternalLink } from 'lucide-react';

export const PreviewLink = () => {
  const { t } = useTranslation();

  return (
    <AnchorButton variant="outline" title={t('sections.studies.preview')} asChild>
      <Link to="../preview">
        {t('sections.studies.preview')}
        <ExternalLink className="h-4 w-4" />
      </Link>
    </AnchorButton>
  );
};
