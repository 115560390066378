import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'urql';
import { useConnectedClient } from 'context/ConnectedClientContext';
import { format, formatISO } from 'date-fns';
import { cn } from '@askable/ui/lib/utils';

import { Button } from '@askable/ui/components/ui/button';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@askable/ui/components/ui/table';
import { toast } from '@askable/ui/components/ui/sonner';
import { TableLoading } from 'components/Loading/TableLoading';
import { SettingsContainer } from '../../components/SettingsContainer';

import { transactionUtils } from 'lib/transaction';
import { utils } from 'lib/utils';
import { DownloadInvoiceMutation } from './data/DownloadInvoice.mutation';
import { TeamTransactionHistoryQuery } from './data/TeamTransactionHistory.query';
import { ArrowDownToLine } from 'lucide-react';

export const SettingsInvoices = () => {
  const { t } = useTranslation();
  const { details } = useConnectedClient();
  const [{ fetching: downloadingInvoice }, downloadInvoice] = useMutation(DownloadInvoiceMutation);

  const [{ data, fetching }] = useQuery({
    query: TeamTransactionHistoryQuery,
    variables: {
      _team_id: details?.ConnectedTeam?._id!,
    },
  });

  const handleDownload = async (transactionId: string) => {
    const downlodedInvoiceResult = await downloadInvoice({
      _id: transactionId,
    });

    if (downlodedInvoiceResult.error) {
      toast.error(downlodedInvoiceResult.error.graphQLErrors[0].message);
      return;
    }

    if (downlodedInvoiceResult.data?.downloadInvoice?.onlineInvoiceUrl) {
      window.open(downlodedInvoiceResult.data?.downloadInvoice?.onlineInvoiceUrl, '_blank');
    }
  };

  const tableData = useMemo(() => {
    return data?.transactionsByTeam ?? [];
  }, [data]);

  return (
    <SettingsContainer width="wide" title={t('sections.settings.invoices.title')}>
      {fetching ? (
        <TableLoading className="py-6" />
      ) : (
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>{t('global.credits')}</TableHead>
              <TableHead>{t('sections.settings.invoices.columns.date')}</TableHead>
              <TableHead>{t('sections.settings.invoices.columns.amount')}</TableHead>
              <TableHead>{t('sections.settings.invoices.columns.status')}</TableHead>
              <TableHead>&nbsp;</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {tableData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} className="text-center text-muted-foreground">
                  {t('sections.settings.billing.emptyTable')}
                </TableCell>
              </TableRow>
            ) : (
              tableData?.map((transaction) => {
                const isTransactionPaid = transaction?.invoice?.amount_due === 0;
                const isLegacy = transactionUtils.isLegacyTransaction(transaction?.created);

                return (
                  <TableRow key={transaction?._id} applyDefaultHoverStyle>
                    <TableCell className="font-medium">
                      {isLegacy
                        ? t('sections.settings.invoices.legacyInvoice')
                        : `${utils.numberWithCommas(transactionUtils.getNumberOfCredits(transaction?.invoice))}`}
                    </TableCell>

                    <TableCell>
                      {transaction?.created ? (
                        <time dateTime={formatISO(transaction?.created)} title={formatISO(transaction?.created)}>
                          {format(transaction?.created, 'dd MMM yyyy')}
                        </time>
                      ) : null}
                    </TableCell>

                    <TableCell>
                      <div className="flex flex-col gap-0">
                        {transaction?.total_amount ? (
                          <div className="amount">{`${utils.getCurrencySymbol(transaction?.currency ?? 'AUD')}${utils.formatMoney(transaction?.total_amount, 2)} ${transaction?.currency}`}</div>
                        ) : null}

                        {transaction?.method === 3 ? (
                          <div className="flex w-fit items-center gap-1 rounded-md bg-warning p-1 text-xs font-medium leading-none text-warning-foreground">
                            {t('sections.settings.invoices.viaInvoice')}
                          </div>
                        ) : null}

                        {transaction?.method === 2 && !!transaction?.Team?.settings?.billing?.stripe?.payment?.last4 ? (
                          <div className="bg-primary-medium flex w-fit items-center gap-1 rounded-md p-1 text-xs font-medium leading-none text-foreground">
                            {`${t('sections.settings.invoices.viaCreditCard')} ${transaction?.Team?.settings?.billing?.stripe?.payment?.last4}`}
                          </div>
                        ) : null}
                      </div>
                    </TableCell>

                    <TableCell>
                      <div
                        className={cn('flex w-fit items-center gap-1 rounded-md p-1 text-xs font-medium leading-none', {
                          'bg-success text-success-foreground': isTransactionPaid,
                          'bg-destructive text-destructive-foreground': !isTransactionPaid,
                        })}
                      >
                        {isTransactionPaid
                          ? t('sections.settings.invoices.paid')
                          : t('sections.settings.invoices.unpaid')}
                      </div>
                    </TableCell>

                    <TableCell>
                      {transaction?.invoice?.xero_id ? (
                        <Button
                          variant="ghost"
                          isLoading={downloadingInvoice}
                          onClick={() => handleDownload(transaction?._id as string)}
                        >
                          <ArrowDownToLine className="h-4 w-4" />
                          {t('sections.settings.invoices.downloadInvoice')}
                        </Button>
                      ) : null}
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      )}
    </SettingsContainer>
  );
};
