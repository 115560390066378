import { RouteObject, Navigate } from 'react-router-dom';
import { RequireAuth } from 'containers/Auth/RequireAuth';
import { KindePropertyWrapper } from 'containers/Auth/KindePropertyWrapper';
import { TooltipProvider } from '@askable/ui/components/ui/tooltip';
import { ThemeProvider } from 'next-themes';
import { HelmetProvider } from 'react-helmet-async';
import { StudiesContainer } from './StudiesContainer';
import { BuildStudy } from './BuildStudy/BuildStudy';
import { Preview } from './Preview/Preview';
import { Recruit } from './Recruit/Recruit';
import { Report } from './Report/Report';
import { Results } from './Results/Results';

export const studiesRoutes: RouteObject[] = [
  {
    path: 'studies/:studyId',
    element: (
      <RequireAuth>
        <KindePropertyWrapper>
          <ThemeProvider attribute="class">
            <HelmetProvider>
              <TooltipProvider>
                <StudiesContainer />
              </TooltipProvider>
            </HelmetProvider>
          </ThemeProvider>
        </KindePropertyWrapper>
      </RequireAuth>
    ),
    children: [
      {
        index: true,
        element: <Navigate to="build" replace />,
      },
      {
        path: 'build',
        element: <BuildStudy />,
      },
      {
        path: 'preview',
        element: <Preview />,
      },
      {
        path: 'recruit',
        element: <Recruit />,
      },
      {
        path: 'results',
        element: <Results />,
      },
      {
        path: 'report',
        element: <Report />,
      },
    ],
  },
];
