import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'urql';
import { cn } from '@askable/ui/lib/utils';
import { useStudyContext } from 'containers/Studies/StudiesContainer';
import { useStudyActiveBlockId } from '../utils/useStudyActiveBlockId';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@askable/ui/components/ui/alert-dialog';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@askable/ui/components/ui/dropdown-menu';
import { Button } from '@askable/ui/components/ui/button';
import { toast } from '@askable/ui/components/ui/sonner';
import { Copy, Ellipsis, Trash } from 'lucide-react';
import { CreateStudyConfigTaskBlock } from 'containers/Studies/BuildStudy/data/CreateStudyConfigTaskBlock.mutation';
import { DeleteStudyConfigTaskBlock } from 'containers/Studies/BuildStudy/data/DeleteStudyConfigTaskBlock.mutation';
import { match } from 'ts-pattern';

export type BlockAction = 'duplicate' | 'delete';

interface BlockActionsProps {
  isVisible?: boolean;
  blockId: string;
}

const createTitleCopy = (title: string) => {
  // Check if title ends with (Copy N) and change it to (Copy N+1)
  // TODO: localise "Copy"
  const titleMatch = title.match(/\(Copy( \d+)?\)$/);
  if (!titleMatch) {
    return `${title} (Copy)`;
  }

  const number = titleMatch[1] ? parseInt(titleMatch[1]) + 1 : 2;
  return title.replace(/\(Copy( \d+)?\)$/, `(Copy ${number})`);
};

export const BlockActions = ({ isVisible = true, blockId }: BlockActionsProps) => {
  const { t } = useTranslation();
  const { study, setNewBlockId } = useStudyContext();
  const { activeBlockId, setActiveBlockId } = useStudyActiveBlockId();
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);

  const [, createStudyConfigTaskBlock] = useMutation(CreateStudyConfigTaskBlock);
  const [, deleteStudyConfigTaskBlock] = useMutation(DeleteStudyConfigTaskBlock);

  const block = useMemo(
    () =>
      match(blockId)
        .with('welcome', () => null)
        .with('thank_you', () => null)
        .otherwise(() => study.draft_config?.task_blocks?.find((b) => b?._id === blockId)),
    [study, blockId],
  );

  const handleDuplicateBlock = async () => {
    try {
      if (!block) {
        throw new Error('Missing block');
      }

      // Calculate new position for copied block to be added underneath the original block
      const blockIndex = study.draft_config?.task_blocks?.findIndex?.((b) => b?._id === blockId);
      const position =
        blockIndex !== undefined && blockIndex > -1 ? blockIndex + 1 : study.draft_config?.task_blocks?.length!;

      const { error, data } = await createStudyConfigTaskBlock({
        input: {
          _id: study._id,
          position,
          task_block: {
            type: block.type,
            title: createTitleCopy(block?.title || ''),
            instructions: block.instructions,
            is_recording_enabled: !!block.is_recording_enabled,
          },
        },
      });

      if (error) {
        throw new Error(error.message);
      }

      // TODO: fix type
      const newBlocks = (data?.createStudyConfigTaskBlock as any)?.draft_config.task_blocks;
      const newBlockId = newBlocks[position]?._id;

      if (newBlockId) {
        setNewBlockId(newBlockId);
        setActiveBlockId(newBlockId);
      }
    } catch (e: any) {
      toast.error(e.message || 'Failed to duplicate block');
    }
  };

  const handleDeleteBlock = async () => {
    try {
      if (!block) {
        throw new Error('Missing block');
      }

      const currentIndex = study.draft_config?.task_blocks?.findIndex((b) => b?._id === blockId);
      const previousBlockId = currentIndex > 0 ? study.draft_config?.task_blocks[currentIndex - 1]?._id : undefined;
      const { error } = await deleteStudyConfigTaskBlock({
        input: {
          _id: study._id,
          _task_block_id: blockId,
        },
      });

      if (error) {
        throw new Error(error.message);
      }

      if (blockId === activeBlockId) {
        setActiveBlockId(blockId === activeBlockId ? previousBlockId || 'welcome' : activeBlockId);
      }
    } catch (e: any) {
      toast.error(e.message || 'Failed to delete block');
    }
  };

  if (!block) {
    // TODO: error handling
    return null;
  }

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            size="icon"
            variant="ghost"
            className={cn(
              `transition-opacity focus:opacity-100 group-hover:opacity-100 group-focus:opacity-100 aria-[expanded=true]:opacity-100
              @[10rem]:flex`,
              { 'opacity-0': !isVisible },
            )}
          >
            <Ellipsis className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="z-[2101]">
          <DropdownMenuItem onClick={handleDuplicateBlock}>
            <Copy className="h-4 w-4" /> {t('sections.studies.actions.duplicate')}
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={(e) => {
              e.stopPropagation();

              if (block.title === '') {
                handleDeleteBlock();
              } else {
                setIsOpenConfirm(true);
              }
            }}
          >
            <Trash className="h-4 w-4" /> {t('sections.studies.actions.delete')}
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <AlertDialog open={isOpenConfirm}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{t('sections.studies.actions.deleteBlockConfirmTitle')}</AlertDialogTitle>
            <AlertDialogDescription>
              {t('sections.studies.actions.deleteBlockConfirmDescription')}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => setIsOpenConfirm(false)}>{t('global.cancel')}</AlertDialogCancel>
            <AlertDialogAction
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsOpenConfirm(false);
                handleDeleteBlock();
              }}
            >
              {t('global.delete')}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
