import { Fragment, ReactNode, useCallback } from 'react';
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers';

interface BaseItem {
  _id: UniqueIdentifier;
}

interface SortableListProps<T extends BaseItem> {
  items: T[];
  renderItem: (item: T) => ReactNode;
  onChange: (items: T[]) => void;
  isDragging?: (value: boolean) => void;
}

export function SortableList<T extends BaseItem>({ items, renderItem, onChange, isDragging }: SortableListProps<T>) {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 200,
        tolerance: 6,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const onDragEnd = useCallback(
    ({ over, active }: DragEndEvent) => {
      if (over && active.id !== over?.id) {
        const activeIndex = items.findIndex((item) => item?._id === active.id);
        const overIndex = items.findIndex((item) => item?._id === over.id);
        onChange(arrayMove(items, activeIndex, overIndex));
      }

      isDragging?.(false);
    },
    [items, onChange, isDragging],
  );

  return (
    <DndContext
      sensors={sensors}
      onDragEnd={onDragEnd}
      onDragMove={() => isDragging?.(true)}
      modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
    >
      <SortableContext items={items.map((item) => item?._id)}>
        {items.map((item) => (
          <Fragment key={item?._id}>{renderItem(item)}</Fragment>
        ))}
      </SortableContext>
    </DndContext>
  );
}
