import { RouteObject } from 'react-router-dom';
import { RequireAuth } from 'containers/Auth/RequireAuth';
import { KindePropertyWrapper } from 'containers/Auth/KindePropertyWrapper';
import { TooltipProvider } from '@askable/ui/components/ui/tooltip';
import { ThemeProvider } from 'next-themes';
import { HelmetProvider } from 'react-helmet-async';
import { DiscoverContainer } from './DiscoverContainer';

export const discoverRoutes: RouteObject[] = [
  {
    path: 'discover',
    element: (
      <RequireAuth>
        <KindePropertyWrapper>
          <ThemeProvider attribute="class">
            <HelmetProvider>
              <TooltipProvider>
                <DiscoverContainer />
              </TooltipProvider>
            </HelmetProvider>
          </ThemeProvider>
        </KindePropertyWrapper>
      </RequireAuth>
    ),
  },
];
