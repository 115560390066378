import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { FormControl, FormField, FormItem, FormLabel } from '@askable/ui/components/ui/form';
import { InfoPopover } from 'containers/Studies/components/InfoPopover';
import { Switch } from '@askable/ui/components/ui/switch';
import type { FormValues } from 'containers/Studies/BuildStudy/containers/BlockEditor';

export const RecordField = () => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext<FormValues>();

  return (
    <FormField
      control={control}
      name="is_recording_enabled"
      render={({ field }) => (
        <FormItem>
          <div className="flex items-center justify-between gap-2">
            <FormLabel className="flex w-full min-w-[10rem] items-center gap-1 py-2">
              {t('sections.studies.fields.record')}{' '}
              <InfoPopover
                description={t('sections.studies.fields.recordInfoDescription')}
                moreLink="https://help.askable.com"
                title={t('sections.studies.fields.recordInfoTitle')}
              />
            </FormLabel>
            <FormControl>
              <Switch checked={field.value} onCheckedChange={(checked) => setValue(field.name, checked)} />
            </FormControl>
          </div>

          <div className="text-pretty text-sm text-muted-foreground">
            {t('sections.studies.fields.recordDescription')}
          </div>
        </FormItem>
      )}
    />
  );
};
