import { graphql } from 'src/graphql';

export const UpdateStudy = graphql(`
  mutation UpdateStudy($input: UpdateStudyInput!) {
    updateStudy(input: $input) {
      _id
      name
      status
      updated
      type
      created
      updated
    }
  }
`);
