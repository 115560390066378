import { graphql } from 'src/graphql';

export const UpdateStudyConfig = graphql(`
  mutation UpdateStudyConfig($input: UpdateStudyConfigInput!) {
    updateStudyConfig(input: $input) {
      _id

      ... on UnmoderatedStudy {
        draft_config {
          _id
          welcome_block {
            _id
            type
            title
            instructions
          }
          thank_you_block {
            _id
            type
            title
            instructions
          }
        }
      }
    }
  }
`);
