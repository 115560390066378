import { useStudyContext } from 'containers/Studies/StudiesContainer';
import { AnchorButton } from '@askable/ui/components/ui/button';
import { ChevronLeft } from 'lucide-react';
import { useStudyActiveBlockId } from 'containers/Studies/BuildStudy/utils/useStudyActiveBlockId';
import { useMemo } from 'react';
import { BlockIcon } from 'containers/Studies/BuildStudy/components/BlockIcon';
import { PreviewLink } from 'containers/Studies/BuildStudy/components/PreviewLink';
import { match } from 'ts-pattern';

interface Props {
  hasPreviewLink?: boolean;
}

export const BlockPreview = ({ hasPreviewLink = false }: Props) => {
  const { study } = useStudyContext();
  const { activeBlockId } = useStudyActiveBlockId();

  const block = useMemo(
    () =>
      match(activeBlockId)
        .with('welcome', () => study.draft_config?.welcome_block)
        .with('thank_you', () => study.draft_config?.thank_you_block)
        .otherwise(() => study.draft_config?.task_blocks?.find((b) => b?._id === activeBlockId)),
    [study, activeBlockId],
  );

  if (!block) {
    // TODO: error state
    return null;
  }

  return (
    <aside className="build-preview flex flex-col gap-3 overflow-auto p-4" id="build-preview">
      {hasPreviewLink ? (
        <div className="flex items-center justify-between gap-2">
          <div>
            <AnchorButton size="icon" variant="ghost" href="#build-editor" className="lg:invisible">
              <ChevronLeft className="h-4 w-4" />
            </AnchorButton>
          </div>
          <PreviewLink />
        </div>
      ) : null}

      <div className="h-full min-w-[20rem] rounded-xl bg-background p-6 shadow">
        <div className="flex flex-col gap-10">
          <div className="w-fit">
            <BlockIcon type={block.type} size="lg" hasBackground={false} />
          </div>

          <div className="flex flex-col gap-3">
            <h3 className="text-2xl font-semibold">{block.title}</h3>
            {block.instructions?.split('\n\n')?.map((line) => (
              <p key={line} className="max-w-text text-lg">
                {line.trim()}
              </p>
            ))}
          </div>
        </div>
      </div>
    </aside>
  );
};
