import { graphql } from 'src/graphql';

export const UpdateStudyConfigTaskBlock = graphql(`
  mutation UpdateStudyConfigTaskBlock($input: UpdateStudyConfigTaskBlockInput!) {
    updateStudyConfigTaskBlock(input: $input) {
      _id

      ... on UnmoderatedStudy {
        draft_config {
          _id
          task_blocks {
            _id
            type
            title
            instructions
            is_recording_enabled
          }
        }
      }
    }
  }
`);
