import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { useStudyContext } from '../StudiesContainer';
import { getPageTitle } from '../utils/getPageTitle';

export const Results = () => {
  const { t } = useTranslation();
  const { study } = useStudyContext();

  return (
    <>
      <Helmet>
        <title>{getPageTitle(t('sections.studies.results'), study.name)}</title>
      </Helmet>
      <div className="results-container">
        <main className="main overflow-auto p-4">
          <div className="h-full rounded-xl bg-background p-4">{t('sections.studies.results')}</div>
        </main>
      </div>
    </>
  );
};
