import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import diff from 'deep-diff';
import { z } from 'zod';
import { useMutation } from 'urql';

import { blockSchema } from '../data/schemas/blockSchema';
import { useStudyContext } from '../StudiesContainer';
import { InvalidIndicator } from '../components/InvalidIndicator';
import { StudyName } from '../BuildStudy/components/StudyName';
import { StudySavingIndicator } from '../components/StudySavingIndicator';
import { Button } from '@askable/ui/components/ui/button';
import { Tabs, TabsList, TabsTrigger } from '@askable/ui/components/ui/tabs';
import { StudyStatusIndicator } from 'containers/Studies/components/StudyStatusIndicator';
import { StudyStatus } from 'generated/graphql';
import { PublishStudy } from 'containers/Studies/BuildStudy/data/PublishStudy.mutation';
import { toast } from '@askable/ui/components/ui/sonner';

export const StudiesHeader = () => {
  const { t } = useTranslation();
  const { study, isSaving, setIsSaving, newBlockId } = useStudyContext();

  const location = useLocation();
  const section = location.pathname.split('/')[3];

  const [, publishStudy] = useMutation(PublishStudy);

  const navItems = [
    { label: t('sections.studies.build'), to: 'build' },
    { label: t('sections.studies.recruit'), to: 'recruit' },
    { label: t('sections.studies.results'), to: 'results' },
    { label: t('sections.studies.report'), to: 'report' },
  ];

  const isValid = useMemo(() => {
    const welcomeBlock = study.draft_config?.welcome_block;
    const thankYouBlock = study.draft_config?.thank_you_block;
    const blocks = study.draft_config?.task_blocks?.filter((block: any) => block?._id !== newBlockId) || [];
    const arraySchema = z.array(blockSchema);
    const buildParsed = arraySchema.safeParse([welcomeBlock, ...blocks, thankYouBlock]);
    return {
      build: !!buildParsed.success,
    };
  }, [newBlockId, study]);

  const isDirty = useMemo(() => {
    if (study.status === 'draft') {
      return { build: false };
    }
    const buildDiffs = diff({ details: study.draft_config }, { details: study.live_config });
    const filteredDiffs = buildDiffs?.filter((item) => item.path && item.path[item.path.length - 1] !== '_id');
    return { build: !!filteredDiffs?.length };
  }, [study]);

  const handlePublish = useCallback(async () => {
    try {
      setIsSaving(true);
      const { error } = await publishStudy({ input: { _id: study._id } });
      if (error) {
        throw new Error(error.message || 'Failed to publish changes');
      }
    } catch (e: any) {
      toast.error(e.message);
    } finally {
      setIsSaving(false);
    }
  }, [publishStudy, setIsSaving, study._id]);

  return (
    <header className="studies-header left-0 grid grid-cols-2 items-center gap-2 border-b border-border bg-background px-2 py-3 lg:grid-cols-3">
      <StudyName id={study._id} name={study.name} />

      <Tabs asChild defaultValue="build" value={section}>
        <nav className="order-4 col-start-1 col-end-3 w-full justify-self-center lg:order-2 lg:col-start-2 lg:col-end-3 lg:w-fit">
          <TabsList className="w-full">
            {navItems.map((item) => (
              <TabsTrigger asChild value={item.to} key={item.to} className="-lg:!m-0 flex-1">
                <Link to={item.to} replace className="relative">
                  {item.label}
                  {item.to === 'build' && section !== 'build' ? (
                    <InvalidIndicator isValid={isValid.build} isDirty={isDirty.build} />
                  ) : null}
                </Link>
              </TabsTrigger>
            ))}
          </TabsList>
        </nav>
      </Tabs>

      <div className="order-3 flex items-center gap-3 justify-self-end pr-2">
        <StudyStatusIndicator study={study} />
        <StudySavingIndicator isSaving={isSaving} created={study.created} updated={study.updated} />
        <Button onClick={handlePublish} disabled={isSaving}>
          {study.status === StudyStatus.Active
            ? t('sections.studies.publishChanges')
            : t('sections.studies.publishStudy')}
        </Button>
      </div>
    </header>
  );
};
