import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useConnectedClient } from 'context/ConnectedClientContext';
import { Sidebar } from './components/Sidebar';

const isDisplayingHeaderAds = localStorage.getItem('showingHeaderAds') === 'true';
const accountSettings = ['/settings/account', '/settings/notifications'];

export const SettingsContainer = () => {
  const { details } = useConnectedClient();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect researchers to account settings if they try to access team settings
    if (details?.type?.researcher && !accountSettings.includes(pathname)) {
      navigate('/settings/account');
    }
  }, [details, navigate, pathname]);

  return (
    <div
      className="flex h-full flex-col overflow-auto md:flex-row"
      style={{
        height: isDisplayingHeaderAds
          ? 'calc(var(--mainContainerHeight) - var(--headerAdsHeight))'
          : 'var(--mainContainerHeight)',
      }}
    >
      <Sidebar />

      <main className="w-full">
        <Outlet />
      </main>
    </div>
  );
};
