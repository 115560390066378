import CreateAskablePlus from 'components/askablePlus/createAskablePlus';
import { Outlet, RouteObject } from 'react-router-dom';

// External components
import AskablePlusConfirmation from 'components/askablePlus/confirmationScreen';
import ResearcherProjectOffer from 'components/researcher/projectOffer/projectOffer';

export const askablePlusRoutes: RouteObject[] = [
  {
    path: 'askable-plus',
    element: <Outlet />,
    children: [
      {
        path: ':screen',
        element: <CreateAskablePlus />,
      },
      {
        path: ':projectID',
        children: [
          {
            path: 'researcher-offer',
            element: <ResearcherProjectOffer />,
          },
          {
            path: 'confirmation',
            element: <AskablePlusConfirmation />,
          },
          {
            path: ':screen/:subScreen',
            element: <CreateAskablePlus />,
          },
        ],
      },
    ],
  },
];
