import { forwardRef, useMemo, useState } from 'react';
import { useMediaQuery } from '@askable/ui/hooks/user-media-query';
import { useQuery } from 'urql';
import { useTranslation } from 'react-i18next';
import { useCopyToClipboard } from 'shared-utils/hooks';
import { format } from 'date-fns';
import { sleep } from 'radash';

import { OutlineBadge } from 'src/components/OutlineBadge/OutlineBadge';
import { Button } from '@askable/ui/components/ui/button';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@askable/ui/components/ui/collapsible';
import { Skeleton } from '@askable/ui/components/ui/skeleton';
import { Tooltip, TooltipContent, TooltipTrigger } from '@askable/ui/components/ui/tooltip';
import { ChevronDown, ChevronRight, ChevronUp, Link, MessageSquareQuote, Sparkles, UsersRound, X } from 'lucide-react';

import { DiscoverAnalysesInsightsQuery } from 'containers/Discover/containers/Insights/data/DiscoverAnalysesInsights.query';
import { AnalysesMethod } from 'generated/graphql';
import { QuotesByAnalysesIds } from '../data/QuotesByAnalysesIds';
import { FacilitatorList } from './FacilitatorList';
import { PER_PAGE } from 'containers/Discover/containers/Insights/AnalysesInsights';
import type { FilterDateProps } from 'containers/Discover/DiscoverContainer';

type Props = {
  filterDate: FilterDateProps;
  insightId: string | null;
  onSelectedInsightChange: (id: string | null) => void;
};

const DESKTOP_PANEL_WIDTH = 384;
const MOBILE_PANEL_WIDTH = '100%';

const QuotesSection = ({ analysesIds }: { analysesIds: string[] }) => {
  const { t } = useTranslation();
  const [{ data: quotesByAnalysesIdsData, fetching }] = useQuery({
    query: QuotesByAnalysesIds,
    variables: {
      _ids: analysesIds,
    },
  });

  const quotes = useMemo(() => {
    return quotesByAnalysesIdsData?.analyses.nodes.map((analyses) => {
      if (!analyses) {
        return null;
      }
      const { facilitator, participant } = analyses;

      // Fallback to video_name if participant name is empty. If both are empty, use 'Unknown'
      const participantName =
        participant.first_name === '' && participant.last_name === ''
          ? (analyses.video_name ?? t('global.unknown'))
          : `${participant.first_name} ${participant.last_name}`;

      return analyses?.quotes.flatMap((q) => (
        <li key={`quote_${q.start_time}`} id={`quote_${q.start_time}`} className="flex flex-col gap-2">
          <div className="flex items-center justify-between gap-2 text-sm">
            <p className="truncate font-semibold" title={participantName}>
              {participantName}
            </p>

            <div className="flex items-center gap-2">
              <Tooltip>
                <TooltipTrigger asChild>
                  <time
                    dateTime={format(new Date(analyses?.video_started), 'EEE, dd MMM yyyy h:mma')}
                    className="text-nowrap text-right text-xs"
                  >
                    {format(new Date(analyses?.video_started), 'dd MMM yyyy')}
                  </time>
                </TooltipTrigger>
                <TooltipContent>{format(new Date(analyses?.video_started), 'EEE, dd MMM yyyy h:mma')}</TooltipContent>
              </Tooltip>

              {analyses.method === AnalysesMethod.AiModerated ? (
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Sparkles className="h-3.5 w-3.5 text-muted-foreground" />
                  </TooltipTrigger>
                  <TooltipContent>{t('sections.discover.interviewsAiModerated')}</TooltipContent>
                </Tooltip>
              ) : null}

              <FacilitatorList
                popoverTriggerAvatarSize="xs"
                facilitators={[
                  {
                    id: facilitator._id,
                    pictureLink: analyses.facilitator.picture_link,
                    firstName: facilitator.first_name,
                    lastName: facilitator.last_name,
                  },
                ]}
              />
            </div>
          </div>
          <p className="text-sm">{q.text}</p>
        </li>
      ));
    });
  }, [quotesByAnalysesIdsData?.analyses.nodes]);

  if (fetching) {
    return (
      <section className="flex flex-col gap-3 p-4 pt-5">
        <div className="text-xs font-medium text-muted-foreground">{t('sections.discover.quotes')}</div>
        <div className="flex justify-between gap-2">
          <Skeleton className="h-6 w-2/5" /> <Skeleton className="h-6 w-2/5" />
        </div>
        <Skeleton className="h-4" />
        <Skeleton className="h-4 w-3/5" />
      </section>
    );
  }

  return quotesByAnalysesIdsData?.analyses.nodes.length === 0 ? (
    <section className="flex flex-col gap-3 p-4 pt-5">
      <div className="text-xs font-medium">{t('sections.discover.quotes')}</div>
      <div className="text-xs text-muted-foreground">{t('sections.discover.noQuotes')}</div>
    </section>
  ) : (
    <Collapsible defaultOpen asChild>
      <section className="flex flex-col gap-2 py-4 pl-2 pr-4">
        <CollapsibleTrigger asChild className="group">
          <CollapsibleButton title={t('sections.discover.quotes')} />
        </CollapsibleTrigger>
        <CollapsibleContent>
          <ul className="flex flex-col gap-6 p-2 pr-0">{quotes}</ul>
        </CollapsibleContent>
      </section>
    </Collapsible>
  );
};

const CollapsibleButton = forwardRef<HTMLButtonElement, { title: string }>(({ title, ...props }, ref) => (
  <Button
    ref={ref}
    variant="ghost"
    size="sm"
    {...props}
    className="group font-medium aria-[expanded=true]:bg-transparent aria-[expanded=true]:hover:bg-accent"
  >
    {title}
    <ChevronRight className="h-4 w-4 group-data-[state=open]:hidden" />
    <ChevronDown className="hidden h-4 w-4 group-data-[state=open]:block" />
  </Button>
));

export const InsightDetailsPanel = ({ filterDate, insightId, onSelectedInsightChange }: Props) => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery('(min-width: 40rem)');
  const copyToClipboard = useCopyToClipboard();
  const [hasCopied, setHasCopied] = useState(false);

  const [{ data, fetching }] = useQuery({
    query: DiscoverAnalysesInsightsQuery,
    variables: {
      filter: {
        updated: { gte: filterDate.date },
      },
      first: PER_PAGE,
    },
    requestPolicy: 'cache-only',
  });

  const insightIndex = useMemo(
    () => data?.analysesInsights?.nodes?.findIndex((n) => n?._id === insightId) ?? -1,
    [data?.analysesInsights?.nodes, insightId],
  );

  const insight = data?.analysesInsights?.nodes?.[insightIndex];
  const isOpen = !!insight;
  const isFirstInsight = insightIndex === 0;
  const isLastInsight =
    typeof data?.analysesInsights?.nodes?.length === 'number' &&
    insightIndex === data.analysesInsights.nodes.length - 1;
  const panelWidth = isDesktop ? DESKTOP_PANEL_WIDTH : MOBILE_PANEL_WIDTH;

  const onClose = () => {
    onSelectedInsightChange(null);
  };

  const onNextPrev = (type: 'next' | 'prev') => () => {
    if (!data?.analysesInsights.nodes) {
      return;
    }

    const nextIndex = insightIndex + (type === 'next' ? 1 : -1);
    const nextInsight = data?.analysesInsights?.nodes?.[nextIndex];

    if (nextInsight) {
      onSelectedInsightChange(nextInsight._id);
    }
  };

  const onCopyLink = async () => {
    copyToClipboard(`${window.location.origin}/discover?id=${insightId}`);
    setHasCopied(true);
    await sleep(5000);
    setHasCopied(false);
  };

  return (
    <div
      className="absolute right-0 flex h-full flex-col overflow-hidden bg-background transition-all duration-75 ease-in-out sm:relative"
      style={{ width: isOpen ? panelWidth : 0 }}
    >
      <div
        className="fixed h-full overflow-auto border-l border-border pb-16 text-foreground shadow-md dark:bg-accent"
        style={{ width: panelWidth }}
      >
        <div
          className="sticky top-0 z-10 flex w-full items-center justify-between border-b border-border bg-background/90 p-2 backdrop-blur-sm
            sm:p-3"
        >
          <div className="flex gap-1">
            <Button
              aria-label={t('global.close')}
              onClick={onClose}
              size="icon"
              variant="ghost"
              data-testid="close-panel"
            >
              <X className="h-4 w-4" />
            </Button>

            <Button
              aria-label={t('global.previous')}
              disabled={isFirstInsight}
              onClick={onNextPrev('prev')}
              size="icon"
              variant="outline"
              data-testid="prev"
            >
              <ChevronUp className="h-4 w-4" />
            </Button>

            <Button
              aria-label={t('global.next')}
              disabled={isLastInsight}
              onClick={onNextPrev('next')}
              size="icon"
              variant="outline"
              data-testid="next"
            >
              <ChevronDown className="h-4 w-4" />
            </Button>
          </div>

          {hasCopied ? (
            <div className="text-sm font-medium">{t('sections.discover.copyLinkSuccess')}</div>
          ) : (
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  aria-label={t('sections.discover.copyLink')}
                  onClick={onCopyLink}
                  size="icon"
                  variant="outline"
                  data-testid="copy-link"
                >
                  <Link className="h-4 w-4" />
                </Button>
              </TooltipTrigger>
              <TooltipContent side="bottom">{t('sections.discover.copyLink')}</TooltipContent>
            </Tooltip>
          )}
        </div>

        <div className="divide-stroke divide-y pb-16">
          {fetching ? (
            <>
              <section className="flex flex-col gap-2 p-4">
                <Skeleton className="h-6 w-2/5" />
                <Skeleton className="h-4" />
                <Skeleton className="h-4" />
                <Skeleton className="h-4 w-3/5" />
              </section>

              <section className="flex flex-col gap-3 p-4 pt-5">
                <div className="text-xs font-medium text-muted-foreground">{t('sections.discover.properties')}</div>
                <Skeleton className="h-4 w-3/5" />
                <Skeleton className="h-4 w-3/5" />
                <Skeleton className="h-4 w-3/5" />
              </section>
            </>
          ) : (
            <>
              <section className="flex flex-col gap-2 p-4">
                <h3 className="text-md font-semibold leading-tight">{insight?.title}</h3>
                <p className="text-pretty text-sm leading-normal">{insight?.summary}</p>
              </section>

              <Collapsible defaultOpen asChild>
                <section className="flex flex-col gap-2 py-4 pl-2 pr-4">
                  <CollapsibleTrigger asChild className="group ">
                    <CollapsibleButton title={t('sections.discover.properties')} />
                  </CollapsibleTrigger>

                  <CollapsibleContent className="flex flex-col gap-4 p-2 text-xs">
                    <div className="flex gap-4">
                      <div className="w-24">{t('sections.discover.quotes')}</div>
                      <div className="col-span-2 flex gap-2">
                        <MessageSquareQuote className="h-3 w-3" /> {insight?.quote_count}
                      </div>
                    </div>

                    <div className="flex gap-4">
                      <div className="w-24">{t('sections.discover.interviews')}</div>
                      <div className="col-span-2 flex gap-2">
                        <UsersRound className="h-3 w-3" /> {insight?.user_count}
                      </div>
                    </div>

                    <div className="flex gap-4">
                      <div className="w-24">{t('sections.discover.themes')}</div>
                      <div className="-mt-1 flex flex-wrap items-center gap-1 text-xs">
                        {insight?.themes.map((theme) => (
                          <OutlineBadge key={theme.name} text={theme.name} color={theme.accent} />
                        ))}
                      </div>
                    </div>
                  </CollapsibleContent>
                </section>
              </Collapsible>
            </>
          )}
          <QuotesSection analysesIds={insight?._analyses_ids || []} />
        </div>
      </div>
    </div>
  );
};
