import { useMutation } from 'urql';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useCallback, useEffect, useMemo } from 'react';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { blockSchema } from 'containers/Studies/data/schemas/blockSchema';
import { useStudyContext } from 'containers/Studies/StudiesContainer';
import { EmptyState } from 'containers/Studies/components/EmptyState';
import { BlockActions } from '../components/BlockActions';
import { BlockIcon } from '../components/BlockIcon';
import { FigmaField } from '../components/Fields/FigmaField';
import { InputField } from '../components/Fields/InputField';
import { RecordField } from '../components/Fields/RecordField';
import { TextareaField } from '../components/Fields/TextareaField';
import { AnchorButton } from '@askable/ui/components/ui/button';
import { useStudyActiveBlockId } from '../utils/useStudyActiveBlockId';
import { ChevronLeft, Eye } from 'lucide-react';
import { UpdateStudyConfig } from 'containers/Studies/BuildStudy/data/UpdateStudyConfig.mutation';
import { UpdateStudyConfigTaskBlock } from 'containers/Studies/BuildStudy/data/UpdateStudyConfigTaskBlock.mutation';
import { toast } from '@askable/ui/components/ui/sonner';
import { match } from 'ts-pattern';

export type FormValues = z.infer<typeof blockSchema>;

const recordableTypes = ['figma_prototype'];

export const BlockEditor = () => {
  const { t } = useTranslation();

  const [, updateStudyConfig] = useMutation(UpdateStudyConfig);
  const [, updateStudyConfigTaskBlock] = useMutation(UpdateStudyConfigTaskBlock);

  const { activeBlockId, setActiveBlockId } = useStudyActiveBlockId();
  const { study, newBlockId, setIsSaving } = useStudyContext();

  const block = useMemo(
    () =>
      match(activeBlockId)
        .with('welcome', () => study.draft_config?.welcome_block)
        .with('thank_you', () => study.draft_config?.thank_you_block)
        .otherwise(() => study.draft_config?.task_blocks?.find((b) => b?._id === activeBlockId)),
    [study, activeBlockId],
  );

  const form = useForm<FormValues>({
    mode: 'all',
    resolver: zodResolver(blockSchema),
    defaultValues: {
      instructions: block?.instructions,
      is_recording_enabled: block?.is_recording_enabled,
      title: block?.title,
      type: block?.type,
    },
  });

  // Validate form immediately if user is not currently creating a new one
  useEffect(() => {
    if (block?._id !== newBlockId) {
      form.trigger();
    }
  }, [block?._id, form, newBlockId, setActiveBlockId]);

  const handleSubmit = useCallback<Parameters<typeof form.handleSubmit>[0]>(
    async (values) => {
      try {
        setIsSaving(true);

        // System blocks (welcome | thankyou) are stored in the study config, so we handle
        // them differently here
        const res = await match(values.type)
          .returnType<Promise<any>>()
          .with('welcome', () =>
            updateStudyConfig({
              input: {
                _id: study._id,
                welcome_block: {
                  title: values.title,
                  instructions: values.instructions,
                },
              },
            }),
          )
          .with('thank_you', () =>
            updateStudyConfig({
              input: {
                _id: study._id,
                thank_you_block: {
                  title: values.title,
                  instructions: values.instructions,
                },
              },
            }),
          )
          .otherwise(() =>
            updateStudyConfigTaskBlock({
              input: {
                _id: study._id,
                task_block: {
                  _id: block?._id,
                  title: values.title,
                  instructions: values.instructions,
                  is_recording_enabled: values.is_recording_enabled,
                },
              },
            }),
          );

        if (res.error) {
          throw new Error(res.error.message || 'Update failed');
        }
      } catch (e: any) {
        toast.error(e.message);
      } finally {
        setIsSaving(false);
      }
    },
    [block?._id, setIsSaving, study?.draft_config?._id, updateStudyConfig, updateStudyConfigTaskBlock],
  );

  // Auto submit the form on every change
  // We're intentially bypassing react hook form here because we always want to submit, even when there
  // are validation errors!
  useEffect(() => {
    const subscription = form.watch(() => {
      if (block?._id !== newBlockId) {
        form.trigger();
      }
      handleSubmit(form.getValues());
    });
    return () => subscription.unsubscribe();
  }, [block?._id, form, form.handleSubmit, form.watch, handleSubmit, newBlockId]);

  const isSystemBlock = block?.type ? ['welcome', 'thank_you'].includes(block.type) : false;

  return (
    <main className="build-editor overflow-auto lg:p-4" id="build-editor">
      <div className="flex min-h-full flex-col gap-4 bg-background p-4 lg:rounded-xl lg:p-6">
        {block ? (
          <FormProvider {...form}>
            <form className="flex flex-col gap-6">
              <header className="flex items-center justify-between gap-2">
                <h2 className="break-word flex items-center gap-2 text-lg font-semibold leading-tight text-foreground lg:gap-3">
                  <div className="block lg:hidden">
                    <AnchorButton size="icon" variant="ghost" href="#build-nav">
                      <ChevronLeft className="h-4 w-4" />
                    </AnchorButton>
                  </div>
                  <BlockIcon type={block?.type} />
                  {t(`sections.studies.blocks.${block?.type}`)}
                </h2>

                <div className="flex items-center gap-2">
                  <div className="block lg:hidden">
                    <div className="flex items-center gap-2 lg:hidden">
                      <AnchorButton size="icon" variant="outline" href="#build-preview">
                        <Eye className="h-4 w-4" />
                      </AnchorButton>
                    </div>
                  </div>

                  {!isSystemBlock ? <BlockActions blockId={block?._id!} /> : null}
                </div>
              </header>

              <InputField
                name="title"
                isFocused
                label={isSystemBlock ? t('sections.studies.fields.title') : t('sections.studies.fields.taskTitle')}
                placeholder={
                  isSystemBlock
                    ? t('sections.studies.fields.titlePlaceholder')
                    : t('sections.studies.fields.taskTitlePlaceholder')
                }
              />

              <TextareaField
                name="instructions"
                label={`${
                  isSystemBlock ? t('sections.studies.fields.description') : t('sections.studies.fields.instructions')
                } (${t('formFields.optional').toLocaleLowerCase()})`}
                placeholder={
                  isSystemBlock
                    ? t('sections.studies.fields.descriptionPlaceholder')
                    : t('sections.studies.fields.instructionsPlaceholder')
                }
              />

              {block?.type === 'figma_prototype' ? <FigmaField /> : null}

              {recordableTypes.includes(block?.type) ? (
                <>
                  <hr />
                  <RecordField />
                </>
              ) : null}
            </form>
          </FormProvider>
        ) : (
          <EmptyState title={t('sections.studies.taskNotFound')} />
        )}
      </div>
    </main>
  );
};
