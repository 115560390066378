import { graphql } from 'src/graphql';

export const DeleteStudyConfigTaskBlock = graphql(`
  mutation DeleteStudyConfigTaskBlock($input: DeleteStudyConfigTaskBlockInput!) {
    deleteStudyConfigTaskBlock(input: $input) {
      _id
      ... on UnmoderatedStudy {
        draft_config {
          _id
          task_blocks {
            _id
            type
            title
            instructions
          }
        }
      }
    }
  }
`);
