import { cn } from '@askable/ui/lib/utils';
import { Box, Grid, GridItem, Heading, Table, Thead, Tbody, Tr, Th, Td, TableContainer } from 'ui';
import { Alert, AlertDescription } from '@askable/ui/components/ui/alert';
import { Button } from '@askable/ui/components/ui/button';
import { Input } from '@askable/ui/components/ui/input';
import { X } from 'lucide-react';
import { useMutation } from 'urql';

import { Maybe } from 'generated/graphql';
import { useState } from 'react';
import { FieldValues, useFieldArray, useForm } from 'react-hook-form';
import { useConnectedClient } from 'context/ConnectedClientContext';
import { InviteToTeamMutation } from './data/InviteToTeam.mutation';
import { toast } from '@askable/ui/components/ui/sonner';

type Props = {
  client?: any;
  title?: Maybe<string>;
  enforcePhone?: boolean;
  className?: string;
  showCloseIcon?: boolean;
  onDone?: () => void;
  onClose?: () => void;
};

type Invitation = {
  email: string;
  name: string;
};

type InvitationsFormValues = FieldValues & {
  invitations: Invitation[];
};

function InviteTeammate(props: Props) {
  const defaultInvitationFields = {
    email: '',
    name: '',
  };

  const clientDetails = useConnectedClient();

  const [, inviteToJoinTeam] = useMutation(InviteToTeamMutation);

  const [sentInvites, setSentInvites] = useState<Invitation[]>([]);

  const { control, register, handleSubmit, formState } = useForm<InvitationsFormValues>({
    defaultValues: {
      invitations: [defaultInvitationFields, defaultInvitationFields, defaultInvitationFields],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'invitations',
  });

  const [loading, setLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const onSubmit = async (formValues: InvitationsFormValues) => {
    setLoading(true);
    const finalInvitations = formValues.invitations.filter((invite) => !!invite.email);

    const results = await Promise.all(
      finalInvitations.map((invitation) => {
        return inviteToJoinTeam({
          _id: clientDetails?.details?.team?.id!,
          email: invitation.email,
          name: invitation.name,
          role: 'company_manager',
          enforcePhone: props.enforcePhone,
        }).then((result) => {
          return {
            error: result.error,
          };
        });
      }),
    );

    const hasErrors = results.some((result) => !!result.error);

    if (hasErrors) {
      results.forEach((result) => {
        if (result.error) {
          toast.error('Failed to send invite', {
            description: result.error.message,
          });
        }
      });

      setLoading(false);

      return;
    }

    setSentInvites(finalInvitations);

    setLoading(false);
    setShowConfirmation(true);
  };

  const renderForm = () => {
    return (
      <Grid templateColumns="repeat(2, 1fr) auto" gap={4} w="full" my={6}>
        <GridItem>
          <Heading size="sm">Email address</Heading>
        </GridItem>
        <GridItem>
          <Heading size="sm">Full name (optional)</Heading>
        </GridItem>
        <Box /> {/* Filler component */}
        {fields.map((field, index) => {
          const error = formState.errors.invitations?.[index];

          return (
            <>
              <GridItem key={field.id}>
                <Input
                  type="email"
                  required={index === 0}
                  {...register(`invitations.${index}.email`, { required: index === 0 })}
                />
                {error?.email?.message ? <div className="text-xs text-destructive">{error?.email?.message}</div> : null}
              </GridItem>
              <GridItem>
                <Input type="text" {...register(`invitations.${index}.name`)} />
              </GridItem>
              <GridItem alignSelf="flex-end" justifySelf="center">
                <Button
                  variant="ghost"
                  onClick={() => {
                    if (index === 0) {
                      return;
                    }
                    remove(index);
                  }}
                >
                  <X className="h-4 w-4" />
                  <span className="sr-only">Remove</span>
                </Button>
              </GridItem>
            </>
          );
        })}
        <GridItem>
          <Button
            variant="link"
            onClick={() => {
              append(defaultInvitationFields);
            }}
          >
            + Add another
          </Button>
        </GridItem>
      </Grid>
    );
  };

  const renderInvitationForm = () => {
    return (
      <form onSubmit={handleSubmit(onSubmit)} className="pb-2">
        {props.title !== null && (
          <h1>
            Invite people to <strong>{clientDetails?.details?.team?.name}</strong>
          </h1>
        )}
        {renderForm()}
        <div className="flex justify-end">
          <Button type="submit" isLoading={loading}>
            Send invitations
          </Button>
        </div>
      </form>
    );
  };

  const renderConfirmationMessage = () => {
    return (
      <div className="pb-2">
        <Alert variant="info" className="max-w-sm">
          <AlertDescription>Your invitations have been sent</AlertDescription>
        </Alert>

        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Email address</Th>
                <Th>Full name (optional)</Th>
              </Tr>
            </Thead>
            <Tbody>
              {sentInvites.map((invite) => {
                return (
                  <Tr key={`${invite.email}-${invite.name ?? 'name'}`}>
                    <Td>{invite.email}</Td>
                    <Td>{invite.name}</Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          <div className="flex justify-end pt-2">
            <Button
              onClick={() => {
                props?.onDone?.();
                props?.onClose?.();
              }}
            >
              Done
            </Button>
          </div>
        </TableContainer>
      </div>
    );
  };

  return (
    <div className={cn(props.className)}>
      {!showConfirmation ? renderInvitationForm() : renderConfirmationMessage()}
    </div>
  );
}

export default InviteTeammate;
