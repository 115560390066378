import { useState } from 'react';

import { columns as columnsData } from './projects-columns';

import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Helmet } from 'react-helmet-async';
import { Table } from '../components/Table';
import { ProjectsTableSchemaType } from './ProjectsSchema';
import { ProjectsToolbar } from './ProjectsToolbar';
import { useConnectedClient } from 'context/ConnectedClientContext';
import { useProjectsData } from './hooks/useProjectsData';
import { ProjectsTableSkeletons } from './ProjectsTableSkeletons';

type Props<TData, TValue> = {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  fetching: boolean;
};

function ProjectsTable<TData extends ProjectsTableSchemaType, TValue>({
  columns,
  data,
  fetching,
}: Props<TData, TValue>) {
  const { details } = useConnectedClient();
  const [rowSelection, setRowSelection] = useState({});
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [sorting, setSorting] = useState<SortingState>([]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      rowSelection,
      columnFilters,
    },
    // These defaultColumn value is required for fixed columns
    defaultColumn: {
      minSize: 0,
      size: Number.MAX_SAFE_INTEGER,
      maxSize: Number.MAX_SAFE_INTEGER,
    },

    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  return (
    <Table
      fetching={fetching}
      skeletons={<ProjectsTableSkeletons />}
      toolbar={<ProjectsToolbar table={table} />}
      table={table}
      searchId="name"
      tableNoDataToDisplayUi={{
        title: 'Askable Plus',
        description1: !details?.type?.researcher
          ? "Here you'll find all Askable Plus projects created by you or anyone on your team."
          : "Here you'll find all of the Askable Plus projects you are assigned to.",
        description2: !details?.type?.researcher
          ? 'Create a new project, provide your brief, and work with one of our researchers throughout the project process.'
          : undefined,
        createButton: !details?.type?.researcher
          ? {
              link: '/askable-plus/create',
              text: 'Create a project',
            }
          : undefined,
      }}
    />
  );
}

export const ProjectsContainer = () => {
  const { fetching, data } = useProjectsData();

  return (
    <>
      <Helmet>
        <title>Askable plus</title>
      </Helmet>
      <div className="w-full flex-auto">
        <ProjectsTable fetching={fetching} data={data} columns={columnsData} />
      </div>
    </>
  );
};
