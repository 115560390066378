import { AccessorColumnDef } from '@tanstack/react-table';

import { StudiesTableSchemaType } from 'containers/Studies/data/table/studiesTableSchema';
import { ColumnHeader } from 'containers/Root/components/ColumnHeader';
import { CellName } from 'containers/Root/components/CellName';
import { format } from 'date-fns';

export const columns: AccessorColumnDef<StudiesTableSchemaType>[] = [
  {
    accessorKey: 'name',
    header: ({ column }) => <ColumnHeader column={column} title="Title" />,
    cell: ({ row }) => {
      const url = `/studies/${row.original._id}/build`;
      return <CellName url={url}>{row.original.name}</CellName>;
    },
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'type',
    enableResizing: false,
    maxSize: 176,
    minSize: 176,
    size: 176,
    header: ({ column, table }) => (
      <ColumnHeader disableSortByPageCount={table.getPageCount()} column={column} title="Type" />
    ),
    cell: ({ getValue }) => {
      return <div>{getValue() as string}</div>;
    },
  },
  {
    accessorKey: 'status',
    enableResizing: false,
    maxSize: 144,
    minSize: 144,
    size: 144,
    header: ({ column, table }) => (
      <ColumnHeader disableSortByPageCount={table.getPageCount()} column={column} title="Status" />
    ),
    cell: ({ getValue }) => {
      return <div>{getValue() as string}</div>;
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: 'created',
    enableResizing: false,
    maxSize: 160,
    minSize: 160,
    size: 160,
    header: ({ column, table }) => (
      <ColumnHeader disableSortByPageCount={table.getPageCount()} column={column} title="Created date" />
    ),
    cell: ({ getValue }) => {
      const created = getValue();
      if (!created) {
        return null;
      }
      return <div className="text-muted-foreground">{format(created as number, 'd MMM yy')}</div>;
    },
  },
];
