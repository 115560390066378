import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AnchorButton, Button } from '@askable/ui/components/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '@askable/ui/components/ui/popover';
import { ExternalLink, Info } from 'lucide-react';

/**
 * Extends the Popover component with custom styling
 * Persist the hidden state in local storage
 */

interface InfoPopoverProps {
  description: string;
  isOpen?: boolean;
  moreLink?: string;
  title: string;
}

export const InfoPopover = ({ isOpen: externalIsOpen, description, moreLink, title }: InfoPopoverProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(externalIsOpen);

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="ghost"
          size="icon_sm"
          className="rounded-full text-input hover:text-foreground-subtle focus:text-foreground-subtle aria-[expanded=true]:text-foreground"
          onClick={(e) => e.stopPropagation()}
        >
          <Info className="h-4 w-4" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="flex flex-col gap-2 p-4" align="center">
        <div className="font-semibold">{title}</div>
        <div className="text-pretty text-sm">{description}</div>

        {moreLink ? (
          <div className="flex justify-end">
            <AnchorButton href={moreLink} variant="info" target="_blank">
              {t('global.learnMore')} <ExternalLink className="h-3 w-3" />
            </AnchorButton>
          </div>
        ) : null}
      </PopoverContent>
    </Popover>
  );
};
