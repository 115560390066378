import { useStudyContext } from 'containers/Studies/StudiesContainer';
import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

let lastBlockId: string | undefined;

export function useStudyActiveBlockId() {
  const { study } = useStudyContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const activeBlockId = searchParams.get('block_id');
  const setActiveBlockId = useCallback(
    (newBlockId: string, replace?: boolean) => {
      if (newBlockId !== activeBlockId) {
        setSearchParams({ ...Object.fromEntries(searchParams), block_id: newBlockId }, { replace });
      }
    },
    [searchParams, setSearchParams, activeBlockId],
  );

  useEffect(() => {
    if (activeBlockId) {
      lastBlockId = activeBlockId;
      return;
    }

    // If no id in the url, fall back to the last one or to the welcome block which is guaranteed to be there
    // We only want to use the fallback though if it's still a valid block on the current study (e.g. not if the
    // user has navigated to a different study)
    let fallback = 'welcome';
    if (lastBlockId) {
      if (
        ['welcome', 'thank_you'].includes(lastBlockId) ||
        study?.draft_config?.task_blocks?.find((b) => b?._id === lastBlockId)
      ) {
        fallback = lastBlockId;
      }
    }
    setActiveBlockId(fallback, true);
  }, [activeBlockId, setSearchParams, setActiveBlockId, study?.draft_config?.task_blocks]);

  return {
    activeBlockId,
    setActiveBlockId,
  };
}
