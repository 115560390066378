import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { useStudyContext } from '../StudiesContainer';
import { getPageTitle } from '../utils/getPageTitle';
import { BlockPreview } from 'containers/Studies/BuildStudy/containers/BlockPreview';

export const Preview = () => {
  const { t } = useTranslation();
  const { study } = useStudyContext();

  return (
    <>
      <Helmet>
        <title>{getPageTitle(t('sections.studies.preview'), study.name)}</title>
      </Helmet>
      <div className="preview-container">
        <BlockPreview />
      </div>
    </>
  );
};
