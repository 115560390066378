import { useMemo, CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { cn } from '@askable/ui/lib/utils';
import { diff } from 'deep-diff';
import { StudyBlock, StudyBlockType, StudyTaskBlock } from 'generated/graphql';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Link } from 'react-router-dom';
import { useStudyContext } from 'containers/Studies/StudiesContainer';
import { blockSchema } from 'containers/Studies/data/schemas/blockSchema';
import { BlockActions } from './BlockActions';
import { BlockIcon } from './BlockIcon';
import { CircleAlert, TriangleAlert } from 'lucide-react';
import { match } from 'ts-pattern';

interface Props {
  data: StudyBlock | StudyTaskBlock;
  isActive?: boolean;
  isMovable?: boolean;
}

export const Block = ({ data, isActive, isMovable }: Props) => {
  const { t } = useTranslation();
  const { newBlockId, setNewBlockId, study } = useStudyContext();
  const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({
    id: isMovable ? data?._id : null,
  });

  const isSystemBlock = ['welcome', 'thank_you'].includes(data?.type);

  const blockName = {
    welcome: t('sections.studies.blocks.welcome'),
    thank_you: t('sections.studies.blocks.thank_you'),
    add: t('sections.studies.blocks.add'),
    figma_prototype: t('sections.studies.blocks.figma_prototype'),
    multiple_choice: t('sections.studies.blocks.multiple_choice'),
    open_answer: t('sections.studies.blocks.open_answer'),
    opinion_scale: t('sections.studies.blocks.opinion_scale'),
  };

  const dragStyle: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const isValid = useMemo(() => {
    if (newBlockId === data?._id) {
      // We don't show validation errors while a block is being created
      return true;
    }
    const parsed = blockSchema.safeParse(data);
    return parsed.success;
  }, [data, newBlockId]);

  const isDirty = useMemo(() => {
    if (study?.status === 'draft') {
      return false;
    }
    const draftBlock = data;
    const liveBlock = match(data?.type)
      .with(StudyBlockType.Welcome, () => study.live_config?.welcome_block)
      .with(StudyBlockType.ThankYou, () => study.live_config?.thank_you_block)
      .otherwise(() => {
        const index = study.draft_config?.task_blocks?.findIndex((b) => b?._id === data?._id);
        return study.live_config?.task_blocks[index!];
      });
    const buildDiffs = diff({ details: draftBlock }, { details: liveBlock });
    const filteredDiffs = buildDiffs?.filter((item) => item.path && item.path[item.path.length - 1] !== '_id');
    return !!filteredDiffs?.length;
  }, [data, study.live_config, study?.status]);

  const handleSelectBlock = () => {
    setNewBlockId(null);

    // For mobile view scroll to the editor when a block is selected
    const element = document.getElementById('build-editor');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  if (!data) {
    // TODO: error handling
    return null;
  }

  return (
    <div
      className={cn('w-full', { 'z-50 cursor-grabbing': isDragging })}
      id={data._id}
      {...attributes}
      {...listeners}
      ref={setNodeRef}
      style={dragStyle}
    >
      <Link
        to={{ search: `?block_id=${isSystemBlock ? data.type : data._id}` }}
        onClick={handleSelectBlock}
        className={cn(
          `border-1 flex w-full items-center justify-between rounded-xl border-border bg-background p-1 text-sm
          text-secondary-foreground transition-all delay-75 @[10rem]:w-full`,
          'group',
          'starting:opacity-70',
          '@[10rem]:w-full @[10rem]:justify-start @[10rem]:p-2',
          'hover:border-input focus:border-input active:border-primary has-[button:active]:border-input',
          {
            [`border-primary bg-background ring-[0.5px] ring-primary hover:border-primary focus:border-primary
            has-[button:active]:border-primary`]: isActive,
            'border-input ring-border active:border-input': isDragging && !isActive,
            'pointer-events-none bg-background/50 shadow-md backdrop-blur-sm': isDragging,
          },
        )}
        title={data.title}
      >
        <div className="flex w-full min-w-0 items-center justify-center gap-2 @[10rem]:justify-start">
          <div className={cn('transition-all', { 'cursor-grab': !isSystemBlock })}>
            <BlockIcon type={data.type} />
          </div>

          <div className="hidden flex-col truncate text-left font-medium @[10rem]:flex">
            {data.title ? (
              <div className="truncate">{data.title}</div>
            ) : (
              <div className="truncate text-muted-foreground">
                {t(
                  !isSystemBlock
                    ? 'sections.studies.fields.taskTitlePlaceholder'
                    : 'sections.studies.fields.titlePlaceholder',
                )}
                ...
              </div>
            )}
            <div className="truncate text-xs text-muted-foreground">{blockName[data.type]}</div>
          </div>
        </div>

        <div className="hidden items-center justify-center @[10rem]:grid">
          {isDirty || !isValid ? (
            <div
              className={cn('col-start-1 col-end-2 row-start-1 row-end-2 m-auto p-2 ', {
                'transition-opacity group-hover:opacity-0 group-focus:opacity-0': data._id && !isSystemBlock,
              })}
            >
              {isDirty ? (
                <CircleAlert className="h-4 w-4 text-info" aria-label={t('validation.unpublishedFields')} />
              ) : (
                <TriangleAlert className="h-4 w-4 text-brand" aria-label={t('validation.invalidField')} />
              )}
            </div>
          ) : null}

          {data._id && !isSystemBlock ? (
            <div className="col-start-1 col-end-2 row-start-1 row-end-2" onPointerDown={(e) => e.stopPropagation()}>
              <BlockActions blockId={data._id} isVisible={false} />
            </div>
          ) : null}
        </div>
      </Link>
    </div>
  );
};
