import { useEffect } from 'react';
import { cn } from '@askable/ui/lib/utils';

interface NotificationsBellIndicatorProps {
  unread: number;
  subscribeToNewUnreadNotifications: () => void;
}

export const NotificationsBellIndicator = ({
  unread,
  subscribeToNewUnreadNotifications,
}: NotificationsBellIndicatorProps) => {
  useEffect(() => {
    subscribeToNewUnreadNotifications();
  }, []);

  return unread ? (
    <div
      className={cn(
        `border-1 absolute right-[1px] top-[-2px] flex aspect-square min-w-[18px] items-center justify-center rounded-full
        border-background bg-brand p-[3px] text-[10px] !leading-none text-lightest`,
        { 'p-[2px] text-[9px]': unread >= 10 },
      )}
    >
      {unread >= 10 ? '9+' : unread}
    </div>
  ) : null;
};
