import { AnalyticsBrowser } from '@segment/analytics-next';

type OLD_SESSIONS_EventType =
  | 'change_audio_input_device'
  | 'click_insight_help_menu_feedback'
  | 'click_insight_help_menu_support'
  | 'click_playback_download_notes'
  | 'click_playback_download_transcript'
  | 'click_playback_download_video'
  | 'click_playback_help_menu_articles'
  | 'click_playback_help_menu_feedback'
  | 'click_playback_help_menu_support'
  | 'click_playback_note_timestamp'
  | 'click_playback_transcript_current_position'
  | 'click_playback_transcript_edit'
  | 'click_playback_transcript_utterance_timestamp'
  | 'click_playback_transcript_word'
  | 'click_playback_update_speaker_details'
  | 'click_playback_update_utterance_speaker'
  | 'copy_collection_share_link'
  | 'copy_insight_share_link'
  | 'create_collection'
  | 'create_highlight'
  | 'create_insight'
  | 'create_local_audio_track'
  | 'create_local_tracks'
  | 'create_local_video_track'
  | 'create_tag'
  | 'delete_tag'
  | 'merge_tags'
  | 'playback.page_view'
  | 'playback_transcript_manual_scroll'
  | 'share_collection'
  | 'share_insight'
  | 'update_tag'
  | 'feedback_ask_ai'
  | 'click_ai_moderated_help_menu_feedback';

export type MARKETING_EVENTS = 'marketing.click_on_header_ad';

export type EventType =
  | OLD_SESSIONS_EventType
  | MARKETING_EVENTS
  | 'askable_plus_brief_submitted'
  | 'client_bought_credits'
  | 'booking_question_created'
  | 'draft_booking_created'
  | 'booking_completed'
  | 'signedup'
  | 'ai.ask_ai'
  | 'ai.ask_ai_copy';

export class Analytics {
  private key: string | undefined = undefined;

  private client: AnalyticsBrowser | null = null;

  private shouldIgnoreTracking(): boolean {
    if (!this.key) {
      return true;
    }

    const ignoreTracking = localStorage.getItem('teamIgnoreTracking');
    const connectedAsClient = localStorage.getItem('connectedAsClient');

    try {
      const parsedIgnoreTracking = ignoreTracking ? JSON.parse(ignoreTracking) : false;
      const parsedConnectedAsClient = connectedAsClient ? JSON.parse(connectedAsClient) : false;

      return parsedIgnoreTracking || parsedConnectedAsClient;
    } catch (e) {
      console.error(e);
      return true;
    }
  }

  constructor(key?: string) {
    this.key = key;

    if (typeof window === 'undefined' || !window.location.hostname.match(/iframe/)) {
      this.client = AnalyticsBrowser.load({ writeKey: key || '' }, { cookie: { secure: true } });
    }
  }

  identify = ({
    id,
    name,
    email,
    teamName,
    teamId,
  }: {
    id: string;
    email: string;
    name?: string;
    teamName?: string;
    teamId?: string;
  }) => {
    if (this.shouldIgnoreTracking() || !this.client) {
      return null;
    }

    return this.client.identify(id, {
      name,
      email,
      company: {
        name: teamName,
        id: teamId,
      },
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
  };

  group = (id: string) => {
    if (this.shouldIgnoreTracking() || !this.client) {
      return null;
    }

    return this.client.group(id);
  };

  track = (event: EventType, properties?: object) => {
    if (this.shouldIgnoreTracking() || !this.client) {
      return null;
    }

    return this.client.track(event, properties);
  };

  reset = () => {
    if (this.client) {
      return this.client.reset();
    }

    return null;
  };
}
