import { useTranslation } from 'react-i18next';
import { DateTime } from './DateTime';
import { Loader2, CircleCheckBig } from 'lucide-react';

interface Props {
  isSaving?: boolean;
  created: number;
  updated?: number | null;
}

export const StudySavingIndicator = ({ isSaving, created, updated }: Props) => {
  const { t } = useTranslation();
  const date = updated ?? created;

  return (
    <DateTime date={date}>
      {isSaving ? (
        <Loader2 className="h-3.5 w-3.5 animate-spin opacity-50" aria-label={t('global.saving')} />
      ) : (
        <CircleCheckBig className="h-3.5 w-3.5 text-success" aria-label={t('sections.studies.saved')} />
      )}
    </DateTime>
  );
};
