import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cn } from '@askable/ui/lib/utils';
import { BlockIcon } from './BlockIcon';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '@askable/ui/components/ui/dropdown-menu';
import { Blocks } from '@askable/ui/components/iconsCustom';
import { StudyTaskBlockType } from 'generated/graphql';

const blockOptions = [
  { label: 'tasks' },
  { type: StudyTaskBlockType.FigmaPrototype, isDisabled: false },
  { label: 'questions' },
  { type: 'multiple_choice', isDisabled: true },
  { type: 'open_answer', isDisabled: true },
  { type: 'opinion_scale', isDisabled: true },
] as const;

interface Props {
  isDisabled?: boolean;
  isFullBlock?: boolean;
  // TODO: type should be derived from `blockOptions`, but rn we don't support the disabled types in the BE schema
  onSelect: (type: StudyTaskBlockType) => void;
}

export const AddBlock = ({ isFullBlock = true, isDisabled = false, onSelect }: Props) => {
  const { t } = useTranslation();
  const [side, setSide] = useState<'right' | 'bottom'>('bottom');

  // Dropdown appears on the right side above 768px
  useEffect(() => {
    const updateSide = () => {
      setSide(window.innerWidth >= 768 ? 'right' : 'bottom');
    };

    updateSide();
    window.addEventListener('resize', updateSide);
    return () => window.removeEventListener('resize', updateSide);
  }, []);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <button
          type="button"
          className={cn(
            `border-1 flex w-full items-center justify-between gap-2 rounded-xl border-secondary bg-secondary text-sm ring-[0.5px]
            ring-transparent`,
            'group @[10rem]:w-full @[10rem]:p-2',
            'hover:border-input focus:border-input focus-visible:outline-none active:border-input',
            'aria-[expanded=true]:border-input',
            { 'flex-col @[10rem]:p-4': isFullBlock },
          )}
          disabled={isDisabled}
        >
          <div className={cn('hidden flex-col items-center gap-3', { '@[10rem]:flex': isFullBlock })}>
            <Blocks size={64} />
            <div className="text-balance text-xs font-normal text-muted-foreground">
              {t('sections.studies.blocks.emptyDescription')}
            </div>
          </div>

          <div className={cn('flex items-center gap-1 font-semibold', { 'justify-center py-2': isFullBlock })}>
            <div className="p-1 py-[3px] transition-all">
              <BlockIcon type="add" size="sm" hasBackground={!isFullBlock} />
            </div>
            <div className="hidden @[10rem]:block">{t('sections.studies.blocks.addTitle')}</div>
          </div>
        </button>
      </DropdownMenuTrigger>

      <DropdownMenuContent side={side}>
        {blockOptions.map((block) => {
          if ('label' in block) {
            return (
              <DropdownMenuLabel
                key={`label-${block.label}`}
                className="px-3 text-sm font-medium text-muted-foreground"
              >
                {t(`global.${block.label}`)}
              </DropdownMenuLabel>
            );
          }

          return (
            <DropdownMenuItem
              key={block.type}
              className={cn('group items-start p-3', { 'opacity-50': block.isDisabled })}
              disabled={block.isDisabled}
              onSelect={block.isDisabled ? undefined : () => onSelect(block.type)}
            >
              <div className="transition-all">
                <BlockIcon type={block.type} />
              </div>

              <div className="flex max-w-xs flex-col gap-1 text-left">
                <div className="flex items-center justify-between gap-2">
                  <div className="font-semibold">{t(`sections.studies.blocks.${block.type}` as any)}</div>
                  {block.isDisabled ? (
                    <div className="rounded-sm bg-foreground px-1 py-0 text-xs leading-5 text-background">
                      {t('sections.studies.comingSoon')}
                    </div>
                  ) : null}
                </div>
                <div className="max-w-[34ch] text-muted-foreground">
                  {t(`sections.studies.blocks.${block.type}Description` as any)}
                </div>
              </div>
            </DropdownMenuItem>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
