import { IconProps } from '.';
import { getColorValue } from '@askable/ui/lib/utils';

export const Blocks = ({ bgColor = 'foreground', fgColor = 'background', size = 48 }: IconProps) => {
  const bgHsl = getColorValue(bgColor);
  const fgHsl = getColorValue(fgColor);

  return (
    <svg fill="none" viewBox="0 0 64 64" width={size} height={size}>
      <path fill={bgHsl} d="m58.5 17.5-18 12v8l18-11v-9Z" />
      <path
        fill={bgHsl}
        fillRule="evenodd"
        d="M59 16.6c.3.2.5.5.5.9v9c0 .3-.2.7-.5.9l-18 11a1 1 0 0 1-1.5-.9v-8c0-.3.2-.6.4-.8l18-12a1 1 0 0 1 1 0ZM41.5 30v5.7l16-9.8v-6.5L41.5 30Z"
        clipRule="evenodd"
      />
      <path fill={fgHsl} d="M6 13.5 25 3l17 7 16.5 7a95 95 0 0 1-17 12c-4.4.8-25.8-9.8-35.5-15.5Z" />
      <path
        fill={bgHsl}
        fillRule="evenodd"
        d="M24.5 2.1h.9L25 3l.4-1 2.3 1a9881.8 9881.8 0 0 0 30.6 12.8l.6.3-.4.9.4-1a1 1 0 0 1 .3 1.7A93.2 93.2 0 0 1 41.7 30c-.8.1-1.8 0-2.8-.2L35 28.7A247.9 247.9 0 0 1 5.5 14.4a1 1 0 0 1 0-1.8l19-10.5Zm32.2 15.2-.8-.3a4573 4573 0 0 0-29-12.1L25.1 4 8 13.5a254 254 0 0 0 27.7 13.3l3.5 1c1 .2 1.5.3 1.9.2 4.5-2.2 11.2-7 15.5-10.7Z"
        clipRule="evenodd"
      />
      <path fill={fgHsl} d="M7.5 35c4.2-1.3 14.4-5 16-5 0 0 8.5 2.5 15.5 6s17.5 10 17.5 10l-15 6.5L7.5 35Z" />
      <path
        fill={bgHsl}
        fillRule="evenodd"
        d="m22.3 29.2 1.2-.2h.3l-.3 1 .3-1a65.2 65.2 0 0 1 5.2 1.7 95.8 95.8 0 0 1 21.9 10.8 392.8 392.8 0 0 1 6 3.6h.1l-.5.9.5-.9a1 1 0 0 1 0 1.9l-15 6.5a1 1 0 0 1-.9 0L7 35.9a1 1 0 0 1 .2-1.9l4.4-1.4 4.5-1.5a141.5 141.5 0 0 1 6.2-2Zm32 16.6a391.5 391.5 0 0 0-15.7-9 94 94 0 0 0-15.8-5.7l-1.6.5-4.4 1.3-4.5 1.5-2.2.8 31.4 16.2 12.8-5.6Z"
        clipRule="evenodd"
      />
      <path fill={bgHsl} d="m40.5 52.5-2 10.5L53 57l3.5-11-16 6.5Z" />
      <path
        fill={bgHsl}
        fillRule="evenodd"
        d="M57.2 45.3c.3.2.4.6.3 1l-3.5 11c-.1.3-.3.5-.6.6l-14.5 6a1 1 0 0 1-1.4-1l2-10.6c0-.3.3-.6.6-.7l16-6.5c.4-.2.8-.1 1 .2Zm-15.8 8-1.6 8 12.4-5 2.7-8.6-13.5 5.5Z"
        clipRule="evenodd"
      />
      <path fill={fgHsl} d="m6.5 45.5 1-10.5 33 17-2 11s-11-5-18.5-9-13.5-8.5-13.5-8.5Z" />
      <path
        fill={bgHsl}
        fillRule="evenodd"
        d="M7 34.1a1 1 0 0 1 1 0l33 17c.3.2.6.6.5 1l-2 11a1 1 0 0 1-1.4.8l.4-.9-.4 1-.1-.1a627 627 0 0 1-18.5-9A117 117 0 0 1 6 46.4v-.1h-.1l.6-.8-.6.8a1 1 0 0 1-.4-.9l1-10.5c0-.3.2-.6.5-.8Zm.5 11a81.6 81.6 0 0 0 3.5 2.3 115 115 0 0 0 9.5 5.7 307.7 307.7 0 0 0 17.2 8.5l1.7-9-31-16-.9 8.4Z"
        clipRule="evenodd"
      />
      <path fill={bgHsl} d="M40.6 44.5 14 33l8.6-3.8c.3-.1.5-.1.8 0l24 11a1 1 0 0 1 0 1.9l-6 2.4a1 1 0 0 1-.8 0Z" />
      <path fill={fgHsl} d="M6.5 24 6 13.5C17 20.5 29.2 25 40.5 29v10l-34-15Z" />
      <path
        fill={bgHsl}
        fillRule="evenodd"
        d="M5.5 12.6a1 1 0 0 1 1 0c11 7 23 11.5 34.3 15.5.4.1.7.5.7.9v10a1 1 0 0 1-1.4 1l-34-15a1 1 0 0 1-.6-1L5 13.5c0-.3.2-.7.5-.9Zm1.6 2.8.4 8 32 14v-7.7c-10.6-3.7-22-8-32.4-14.3Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
