import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { useStudyContext } from '../StudiesContainer';
import { getPageTitle } from '../utils/getPageTitle';
import { useStudyActiveBlockId } from 'containers/Studies/BuildStudy/utils/useStudyActiveBlockId';
import { BlocksSidebar } from 'containers/Studies/BuildStudy/containers/BlocksSidebar';
import { BlockEditor } from 'containers/Studies/BuildStudy/containers/BlockEditor';
import { BlockPreview } from 'containers/Studies/BuildStudy/containers/BlockPreview';
import { match } from 'ts-pattern';
import { useMemo } from 'react';

export const BuildStudy = () => {
  const { t } = useTranslation();
  const { study } = useStudyContext();
  const { activeBlockId } = useStudyActiveBlockId();

  // HACK: I think there's something weird going on when a block is added e.g. through duplication
  // where this renders with the new block id before the list has the new block in it, causing the
  // form to initialise wrong with empty values
  // Will investigate and fix separately, for now this helps us ensure the form re-mounts when the
  // block actually becomes available in the db cachde
  const block = useMemo(
    () =>
      match(activeBlockId)
        .with('welcome', () => study.draft_config?.welcome_block)
        .with('thank_you', () => study.draft_config?.thank_you_block)
        .otherwise(() => study.draft_config?.task_blocks?.find((b) => b?._id === activeBlockId)),
    [study, activeBlockId],
  );

  return (
    <>
      <Helmet>
        <title>{getPageTitle(t('sections.studies.build'), study.name)}</title>
      </Helmet>

      <div className="build-container scrollbar-hidden scroll-smooth">
        <BlocksSidebar />
        <BlockEditor key={`${activeBlockId}--${block?._id}`} />
        <BlockPreview hasPreviewLink />
      </div>
    </>
  );
};
