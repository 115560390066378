import { useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { useFormContext } from 'react-hook-form';
import { format } from 'date-fns';
import { cn } from '@askable/ui/lib/utils';
import { DateTime } from 'containers/Studies/components/DateTime';
import { InfoAlert } from 'containers/Studies/components/InfoAlert';
import { InfoPopover } from 'containers/Studies/components/InfoPopover';
import { Button } from '@askable/ui/components/ui/button';
import { Input } from '@askable/ui/components/ui/input';
import { Label } from '@askable/ui/components/ui/label';
import { Figma, FigmaLogo } from '@askable/ui/components/iconsCustom';
import { ArrowRight, RefreshCcw, Trash } from 'lucide-react';
// import type { FormValues } from 'containers/Studies/BuildStudy/containers/BlockEditor';

type ScreenTypes = 'goal' | 'start';

interface FigmaData {
  goal: { _id: string; preview?: string };
  start: { _id: string; preview?: string };
  title: string;
  updated: number;
}

interface SetScreenProps {
  preview?: string;
  screen: ScreenTypes;
  onClick: (screen: ScreenTypes) => void;
}

// @todo replace with real data
const figmaDummyData: FigmaData = {
  goal: { _id: '5', preview: '' },
  start: { _id: '1', preview: 'https://placehold.co/600x400' },
  title: 'Figma_File_Name',
  updated: 1731453286000,
};

const SetScreen = ({ screen, preview, onClick }: SetScreenProps) => {
  const { t } = useTranslation();
  // const { control, setValue } = useFormContext<FormValues>();

  return (
    <button
      id={`select-${screen}-screen`}
      type="button"
      className={cn(
        `border-1 flex aspect-video min-h-16 items-center justify-center overflow-hidden rounded-lg bg-background font-semibold
        leading-tight hover:border-primary focus:border-primary active:border-primary`,
        {
          'border-dashed p-2 hover:border-solid focus:border-solid active:border-solid': !preview,
        },
      )}
      onClick={() => onClick(screen)}
    >
      {preview ? (
        <img src={preview} alt={t(`sections.studies.${screen}`)} />
      ) : (
        t('sections.studies.fields.screenAction', { screen: t(`sections.studies.${screen}`).toLocaleLowerCase() })
      )}
    </button>
  );
};

export const FigmaField = () => {
  const { t } = useTranslation();
  const [figmaLink, setFigmaLink] = useState('');
  const [isImporting, setIsImporting] = useState(false);
  const [importedData, setImportedData] = useState<FigmaData | null>(null);

  const handleImport = async () => {
    // @todo replace with real logic
    setIsImporting(true);

    setTimeout(() => {
      setIsImporting(false);
      setImportedData(figmaDummyData);
    }, 300);

    console.log('figma_prototype_link', figmaLink);
    // setValue('figma_prototype_link, figmaLink);
  };

  const selectScreen = async (screen: ScreenTypes) => {
    // @todo replace with real logic
    setImportedData((prev) => {
      if (!prev) return null;

      return {
        ...prev,
        [screen]: {
          ...prev[screen],
          preview: 'https://placehold.co/600x400',
        },
      };
    });
  };

  return (
    <>
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-2">
          <Label htmlFor="prototype-setup" className="flex items-center gap-1">
            {t('sections.studies.fields.prototypeSetup')}
            <InfoPopover
              description={t('sections.studies.fields.prototypeSetupInfoDescription')}
              moreLink="https://help.askable.com"
              title={t('sections.studies.fields.prototypeSetupInfoTitle')}
            />
          </Label>

          {importedData ? (
            <div className="border-1 flex items-center justify-between gap-3 rounded-xl border-border bg-background-subtle p-3">
              <div className="flex gap-3">
                <div className="aspect-square rounded-lg bg-darkest p-2">
                  <FigmaLogo size={24} />
                </div>

                <div className="flex flex-col">
                  <div className="font-medium">{importedData.title}</div>
                  <div className="text-xs text-foreground-subtle">
                    <DateTime date={importedData.updated}>
                      {t('global.updated')} {format(importedData.updated, 'd MMM yyyy')}
                    </DateTime>
                  </div>
                </div>
              </div>
              <div className="flex gap-1">
                <Button
                  aria-label={t('global.refresh')}
                  className="group"
                  onClick={() => setImportedData(null)}
                  size="icon"
                  variant="ghost"
                >
                  <RefreshCcw className="h-4 w-4 transition-transform group-active:-rotate-45" />
                </Button>
                <Button
                  aria-label={t('global.remove')}
                  className="group"
                  onClick={() => setImportedData(null)}
                  size="icon"
                  variant="ghost"
                >
                  <Trash className="h-4 w-4 transition-transform group-active:rotate-12" />
                </Button>
              </div>
            </div>
          ) : (
            <div className="flex flex-col gap-3">
              <div className="flex gap-1">
                <Input
                  id="prototype-setup"
                  placeholder="www.figma.com/link"
                  onChange={(e) => setFigmaLink(e.target.value)}
                />
                <Button variant="secondary" isLoading={isImporting} onClick={handleImport}>
                  {t('sections.studies.import')}
                </Button>
              </div>

              <InfoAlert
                description={t('sections.studies.fields.prototypeSetupCalloutDescription')}
                Icon={<Figma size={32} />}
                id="figma_info"
                moreLink="https://help.askable.com"
                title={t('sections.studies.fields.prototypeSetupCalloutTitle')}
              />
            </div>
          )}
        </div>
      </div>

      {importedData ? (
        <div className="flex flex-col gap-2">
          <Label htmlFor="select-goal-screen" className="flex items-center gap-1">
            {t('sections.studies.fields.chooseGoalScreen')}
            <InfoPopover
              description={t('sections.studies.fields.chooseGoalScreenInfoDescription')}
              moreLink="https://help.askable.com"
              title={t('sections.studies.fields.chooseGoalScreenInfoTitle')}
            />
          </Label>
          <p className="text-sm text-foreground-subtle">{t('sections.studies.fields.chooseGoalScreenDescription')}</p>

          <div className="border-1 flex items-center justify-between gap-3 rounded-xl border-border bg-background-subtle p-3 text-sm">
            <SetScreen screen="start" preview={importedData.start.preview} onClick={selectScreen} />
            <ArrowRight className="h-6 w-6 opacity-40" />
            <SetScreen screen="goal" preview={importedData.goal.preview} onClick={selectScreen} />
          </div>
        </div>
      ) : null}
    </>
  );
};
