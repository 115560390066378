import { useCallback, useEffect, useRef, useState } from 'react';
import { useMutation } from 'urql';
import { toast } from '@askable/ui/components/ui/sonner';
import { useStudyContext } from 'containers/Studies/StudiesContainer';
import { Block } from '../components/Block';
import { AddBlock } from '../components/AddBlock';
import { SortableList } from '../components/SortableList';
import { useStudyActiveBlockId } from 'containers/Studies/BuildStudy/utils/useStudyActiveBlockId';
import { CreateStudyConfigTaskBlock } from 'containers/Studies/BuildStudy/data/CreateStudyConfigTaskBlock.mutation';
import { StudyTaskBlock, StudyTaskBlockType } from 'generated/graphql';
import { UpdateStudyConfigTaskBlockOrder } from 'containers/Studies/BuildStudy/data/UpdateStudyConfigTaskBlockOrder.mutation';

export const BlocksSidebar = () => {
  const { study, setNewBlockId, setIsSaving } = useStudyContext();
  const { activeBlockId, setActiveBlockId } = useStudyActiveBlockId();

  const [, createStudyConfigTaskBlock] = useMutation(CreateStudyConfigTaskBlock);
  const [, updateStudyConfigTaskBlockOrder] = useMutation(UpdateStudyConfigTaskBlockOrder);

  const navRef = useRef<HTMLElement>(null);

  const [blocks, setBlocks] = useState((study.draft_config?.task_blocks || []) as StudyTaskBlock[]);
  useEffect(() => {
    setBlocks((study.draft_config?.task_blocks || []) as StudyTaskBlock[]);
  }, [study.draft_config?.task_blocks]);

  useEffect(() => {
    return () => setNewBlockId(null);
  }, [setNewBlockId, activeBlockId]);

  const handleAddBlock = useCallback(
    async (type: StudyTaskBlockType) => {
      const { data, error } = await createStudyConfigTaskBlock({
        input: {
          _id: study._id,
          task_block: {
            type,
            title: '',
            instructions: '',
            // TODO: check with product/design what the actual logic is
            is_recording_enabled: type === 'figma_prototype',
          },
        },
      });

      if (error || !data?.createStudyConfigTaskBlock) {
        toast.error(error?.graphQLErrors[0].message ?? 'Error creating block');
        return;
      }

      const element = document.getElementById('build-editor');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }

      const newBlocks = data.createStudyConfigTaskBlock.draft_config.task_blocks;
      const newBlockId = newBlocks[newBlocks.length - 1]?._id!;
      setActiveBlockId(newBlockId);
      setTimeout(() => setNewBlockId(newBlockId), 0);
    },

    [createStudyConfigTaskBlock, setActiveBlockId, setNewBlockId, study._id],
  );

  const updateBlockOrder = useCallback(
    async (ids: string[]) => {
      setIsSaving(true);

      try {
        const { error } = await updateStudyConfigTaskBlockOrder({
          input: { _id: study._id, _task_block_ids: ids },
        });
        if (error) {
          throw error;
        }
      } catch (e) {
        toast.error(e instanceof Error ? e.message : 'Failed to update order');
      } finally {
        setIsSaving(false);
      }
    },
    [study._id, updateStudyConfigTaskBlockOrder, setIsSaving],
  );

  const handleBlockReorder = (updatedBlocks: StudyTaskBlock[]) => {
    const originalIds = blocks.map((b) => b?._id);
    const updatedIds = updatedBlocks.map((b) => b?._id);

    // Order unchanged
    if (updatedIds.length === originalIds.length && updatedIds.every((_id, index) => _id === originalIds[index])) {
      return;
    }

    setBlocks(updatedBlocks);
    updateBlockOrder(updatedIds);
  };

  return (
    <nav
      className="build-nav relative flex flex-col items-center gap-2 overflow-auto bg-background p-4 @container"
      id="build-nav"
      ref={navRef}
    >
      <Block data={study.draft_config?.welcome_block!} isActive={activeBlockId === 'welcome'} />

      <SortableList
        onChange={handleBlockReorder}
        items={blocks}
        renderItem={(block) => (
          <Block key={block?._id} data={block} isMovable isActive={activeBlockId === block?._id} />
        )}
      />

      <AddBlock isFullBlock={blocks?.length === 0} onSelect={handleAddBlock} />
      <Block data={study.draft_config?.thank_you_block!} isActive={activeBlockId === 'thank_you'} />
    </nav>
  );
};
